import React from "react";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import { MoreVert, VisibilityOutlined } from "@mui/icons-material";
import { Button, ButtonGroup, Divider, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { DockToLeftIcon } from "modules/common/icons/dockToLeftIcon";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { BACK_POST_TEXT, DFLT_PUBLISH_TEXT, DISCARD_BTN_TEXT, DRAFT_SAVE_BTN_TEXT, PREVIEW_HOVER_TEXT, REJECT_BTN_TEXT } from "./constants";

export interface IAuthoringAppBarActionsProps {
    actions: Record<AuthoringAppBarAction, IAuthoringAppBarAction | undefined>;
}

export enum AuthoringAppBarAction {
    Save,
    Discard,
    Preview,
    Back,
    ToggleSettingsPanel,
    Publish,
    Reject,
    OpenGuidelines,
}

export interface IAuthoringAppBarAction {
    onClick: () => void;
    disabled: boolean;
    label?: string;
}

interface MenuAction {
    id: string,
    label: string,
    action: IAuthoringAppBarAction | undefined;
}

export const AuthoringAppBarActions: React.FunctionComponent<IAuthoringAppBarActionsProps> = ({
    actions
}) => {
    const publishAction = actions[AuthoringAppBarAction.Publish];
    const saveAction = actions[AuthoringAppBarAction.Save];
    const rejectAction = actions[AuthoringAppBarAction.Reject];
    const previewAction = actions[AuthoringAppBarAction.Preview];
    const backAction = actions[AuthoringAppBarAction.Back];
    const toggleSettingsAction = actions[AuthoringAppBarAction.ToggleSettingsPanel];
    const discardAction = actions[AuthoringAppBarAction.Discard];
    const openGuidelinesAction = actions[AuthoringAppBarAction.OpenGuidelines];

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const groupAnchorRef = React.useRef<HTMLDivElement>(null);
    const showMoreActionsOpen = Boolean(anchorEl);
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

    const reviewActions: MenuAction[] = [
        {
            id: "authoring-app-bar-reject",
            label: REJECT_BTN_TEXT,
            action: rejectAction
        }
    ];

    const smallScreenActions = React.useMemo(
        (): MenuAction[] => [
            {
                id: "authoring-app-bar-back",
                label: BACK_POST_TEXT,
                action: backAction
            },
            {
                id: "authoring-app-bar-preview",
                label: PREVIEW_HOVER_TEXT,
                action: previewAction
            },
            {
                id: "authoring-app-bar-discard",
                label: DISCARD_BTN_TEXT,
                action: discardAction
            },
            {
                id: "authoring-app-bar-save",
                label: saveAction?.label ?? DRAFT_SAVE_BTN_TEXT,
                action: saveAction
            }
        ]
        , [backAction, previewAction, discardAction, saveAction]);

    const onClickMoreActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event?.currentTarget);
    };
    
    const onClickButtonGroupDrop = () => {
        setAnchorEl(groupAnchorRef.current);
    };

    const getMenuOptions = (actions: MenuAction[]): JSX.Element[] => {
        return actions
            .filter((option: MenuAction) => option.action)
            .map((option: MenuAction) =>
                <MenuItem
                    key={option.id}
                    id={option.id}
                    onClick={() => onAction(option.action?.onClick)}
                    disabled={option.action?.disabled}
                >
                    {option.label}
                </MenuItem>
            );
    };

    const getMoreOptionsMenu = (): JSX.Element => {
        let options = isSmallAndSmaller ? [...smallScreenActions] : [];
        options = rejectAction ? [...options, ...reviewActions] : options;

        return <Menu
            id="authoring-app-bar-more-actions-menu"
            open={showMoreActionsOpen}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setAnchorEl(null)}
        >
            {getMenuOptions(options)}
        </Menu>;
    };

    const onAction = (callback?: () => void) => {
        setAnchorEl(null);
        if (callback) callback();
    };

    const getPublishButton = (): JSX.Element => {
        if (!publishAction) return <React.Fragment></React.Fragment>;

        return (
            <Button
                id="authoring-app-bar-publish"
                variant="contained"
                style={{ marginRight: "0px" }}
                sx={{
                    minWidth: "fit-content",
                }}
                {...publishAction}
            >
                {publishAction.label ?? DFLT_PUBLISH_TEXT}
            </Button>
        );
    };

    const getIconButtons = (): JSX.Element => <>
        {
            previewAction &&
            <HoverText
                label={
                    <IconButton
                        id="authoring-app-bar-preview"
                        {...previewAction}
                    >
                        <VisibilityOutlined />
                    </IconButton>
                }
            >
                Preview
            </HoverText>
        }
        {
            openGuidelinesAction &&
            <IconButton
                id="authoring-app-bar-content-guidelines"
                {...openGuidelinesAction}
                style={{ marginLeft: "15px" }}
            >
                <AutoStoriesOutlinedIcon />
            </IconButton>
        }
        {
            toggleSettingsAction &&
            <HoverText
                label={
                    <IconButton
                        id="authoring-app-bar-settings-panel"
                        style={{ background: toggleSettingsAction?.disabled ? "transparent" : "#e4e4e4", marginLeft: "15px" }}
                        onClick={toggleSettingsAction?.onClick}
                    >
                        <DockToLeftIcon />
                    </IconButton>
                }
            >
                {toggleSettingsAction?.label ?? "Settings"}
            </HoverText>
        }
    </>;

    return (
        <>
            {isSmallAndSmaller
                ? <>
                    <IconButton color="primary" size="small" onClick={onClickMoreActions}>
                        <MoreVert />
                    </IconButton>
                    {getMoreOptionsMenu()}
                    {getPublishButton()}
                </>
                : <>
                    {
                        discardAction &&
                        <Button
                            id="authoring-app-bar-discard"
                            {...discardAction}
                            variant="text"
                            sx={{
                                color: "#555555",
                                marginRight: "21px!important"
                            }}
                        >
                            {DISCARD_BTN_TEXT}
                        </Button>
                    }
                    {
                        saveAction &&
                        <Button
                            id="authoring-app-bar-save"
                            variant="outlined"
                            sx={{
                                minWidth: "fit-content",
                                marginRight: "18px!important"
                            }}
                            {...saveAction}
                        >
                            {saveAction.label ?? DRAFT_SAVE_BTN_TEXT}
                        </Button>
                    }

                    {
                        rejectAction
                            ? <ButtonGroup
                                variant="contained"
                                ref={groupAnchorRef}
                            >
                                {getPublishButton()}
                                <Button
                                    id="authoring-app-bar-reject"
                                    variant="contained"
                                    sx={{
                                        minWidth: "40px",
                                        paddingX: "8px"
                                    }}
                                    onClick={onClickButtonGroupDrop}
                                >
                                    <ArrowDropDownIcon />
                                </Button>
                            </ButtonGroup>
                            : publishAction?.disabled && toggleSettingsAction?.disabled
                                ? <HoverText
                                    label={getPublishButton()}
                                >
                                    Complete the required fields to publish.
                                </HoverText>
                                : getPublishButton()
                    }

                    <Divider orientation="vertical" flexItem sx={{ color: "#dde1e5", margin: "0px 20px" }} />

                    {getIconButtons()}
                    {getMoreOptionsMenu()}
                </>}
        </>);
};

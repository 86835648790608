import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';
import * as actions from "network/actions";

import { AudienceFilterValues, DraftAudience } from "modules/audiences/models";
import cookie from 'utils/cookie';
import { AxiosPromise } from 'axios';

export class AudiencesApi {
    constructor(private _sparrowApi: SparrowApi) {
    }
    
    public getAllAudiencesForAuthors = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/audiencesDisplayInfo', { params: { includeReadOnly: true }})
        );
    }

    public getAllAudiences = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/audiences', { params: { includeReadOnly: true }})
        );
    }

    public getAssociatedCategoryTags = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v2/audiences/{id}/categorytags', { params: { id }})
        );
    }

    public getAssociatedDocuments = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v2/audiences/{id}/documents', { params: { id }})
        );
    }

    public getAssociatedNewsletters = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v2/audiences/{id}/newsletters', { params: { id }})
        );
    }

    public getAssociatedTopics = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v2/audiences/{id}/tags', { params: { id }})
        );
    }

    public getAssociatedInvites = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v2/audiences/{id}/invites', { params: { id }})
        );
    }

    public getAudience = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post('/{tenant}/adminapi/v2/audiences/{id}/details', { params: { id }})
        );
    }

    public getEveryoneAudience = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/audiences/everyone')
        );
    }

    public getEveryoneAudienceGroups = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('/{tenant}/adminapi/v1/audiences/groups/everyonegroup')
        );
    }

    public createAudience = (audience: DraftAudience) => {
        const body = { ...audience, groups: audience.groups.map(group => group.groupId), users: audience.users.map(user => user.userId) };
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post('/{tenant}/adminapi/v2/audiences/new', body)
        );
    }

    public getUserSuggestions = (searchText: string, amount: number, alreadyFetched: number) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/suggestion/users', { params: { searchText, amount, alreadyFetched } } )
        );
    }

    public getAudienceUserSuggestion = (audienceId: string, filters: Partial<AudienceFilterValues>) => {
        filters.audienceId = audienceId;
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/audiences/usersuggestions`, filters)
        );
    }

    public getAudienceInviteSuggestion = (audienceId: string, filters: Partial<AudienceFilterValues>) => {
        filters.audienceId = audienceId;
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/audiences/invitesuggestions`, filters)
        );
    }

    public getUserGroupSuggestions = (searchText: string, amount: number,  alreadyFetched: number) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/suggestion/userGroups', { params: { searchText, amount, alreadyFetched } } )
        );
    }

    public addUserToAudience = (audienceId: string, userId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/audiences/${audienceId}/users/${userId}/add`)
        );
    }

    public addInviteToAudience = (audienceId: string, inviteId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/audiences/${audienceId}/invites/${inviteId}/add`)
        );
    }

    public addGroupToAudience = (audienceId: string, groupId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/audiences/${audienceId}/groups/${groupId}/add`)
        );
    }

    public removeUserToAudience = (audienceId: string, userId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/audiences/${audienceId}/users/${userId}/remove`)
        );
    }

    public removeGroupToAudience = (audienceId: string, groupId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/audiences/${audienceId}/groups/${groupId}/remove`)
        );
    }

    public removeInviteFromAudience = (audienceId: string, inviteId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/audiences/${audienceId}/invites/${inviteId}/remove`)
        );
    }

    public getAudienceUserGroupSuggestion = (audienceId: string, filters: Partial<AudienceFilterValues>) => {
        filters.audienceId = audienceId;
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v2/audiences/groupsuggestions`, filters)
        );
    }

    public getAudienceSuggestions = (searchText: string, amount: number, alreadyFetched: number) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/suggestion/audience', { params: { searchText, amount, alreadyFetched } } )
        );
    }

    public getIncludedUsers = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/audiences/{id}/includedUsers', { params: { id }})
        );
    }

    public getIncludedGroups = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/audiences/{id}/includedGroups', { params: { id }})
        );
    }

    public getIncludedEveryoneGroups = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/everyoneaudiences/{id}/includedGroups', { params: { id }})
        );
    }

    public getUsersInGroup = (groupId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/groups/{groupId}/users', { params: { groupId }})
        );
    }

    public getGroupSyncStatus = (groupId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get('/{tenant}/adminapi/v1/groups/{groupId}/syncStatus', { params: { groupId }})
        );
    }

    public saveAudience = (id: string, audience: DraftAudience) => {
        const body = { ...audience, groups: audience.groups.map(group => group.groupId), users: audience.users.map(user => user.userId) };
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post('/{tenant}/adminapi/v2/audiences/{id}', body, { params: { id }})
        );
    }

    public bulkImportUsers = (id: string, emails: string[]) => {
        let body = emails;
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post('/{tenant}/adminapi/v1/audiences/{id}/importusers', body, { params: { id }})
        );
    }

    public bulkReconcileAudience = (id: string, emails: string[]) => {
        let body = emails;
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post('/{tenant}/adminapi/v1/audiences/{id}/reconcileaudience', body, { params: { id }})
        );
    }

    public deleteAudience = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .delete('/{tenant}/adminapi/v2/audiences/{id}', { params: { id }})
        );
    }

    public deleteAndReplaceAudience = (deleteId: string, replaceId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .delete('/{tenant}/adminapi/v1/audiences/{audienceToDelete}/replaceWith/{replacementAudience}', 
                { params: { audienceToDelete: deleteId, replacementAudience: replaceId }})
        );
    }

    public getEveryoneGroupUsersCsv = () => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('/{tenant}/adminapi/v1/groups/userscsv')
        );
    }

    public getAudienceUsersCsv = (id: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .get('/{tenant}/adminapi/v1/audiences/csv/{id}', { params: { id }})
        )
    }

    public updateAudiencesForUser = (audienceIds: string[], userId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
            .post(`/{tenant}/adminapi/v1/audiences/updateUserAudiences/${userId}`, audienceIds)
        )
    }

    public validateEmails = (emails: string[]) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`{tenant}/adminapi/v2/users/validateemails`, emails)
        );
    }

    public getAudienceDetails = (audienceIds: string[]) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`{tenant}/adminapi/v1/audiences/getAudienceDetailsFromList`, audienceIds)
        );
    }

    /**
     * Triggering a resync will run the ResyncAllForTenant Azure Function (proxied by our API) 
     * This will run the UserSync AZ FN on all AD/Okta users that exist in Sparrow. By doing so,
     * Sparrow users and their data will be updated (or deleted) to match their corresponding data in AD/Okta.
     * 
     * NOTE: This will return a 200 status code if the resync job is successfully added to the UserSync queue,
     * but does not check if the actual UserSync work of syncing the users has completed nor was it successful.
     */
    public startAllResync = () => {
        return this._sparrowApi.TenantRequest()
                .post('{tenant}/adminapi/v1/audiences/resync')
    }

    public getUniqueUsers = (audienceIds: string[], grabAll: boolean): AxiosPromise<number> => {
        const body = { audienceIds, "useAllAudiences": grabAll };
        return this._sparrowApi.TenantRequest()
            .post('/{tenant}/adminapi/v1/audiences/uniqueUsers', body);
    }
}

export const CreateAudience = (audience: DraftAudience) => {
    const body = { ...audience, groups: audience.groups.map(group => group.groupId), users: audience.users.map(user => user.userId), invites: audience.invites.map(invite => invite.inviteId) };
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v2/audiences/new`,
        body: JSON.stringify(body)
    });
}

export const DeleteAudience = (id: string) => {
    return actions.NetworkRequest({
        method: "DELETE",
        url: `/{tenant}/adminapi/v2/audiences/${id}`
    });
}

export const DisableAudience = (id: string) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v2/audiences/${id}`,
        body: JSON.stringify({
            isEnabled: false
        })
    });
}

export const EnableAudience = (id: string) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v2/audiences/${id}`,
        body: JSON.stringify({
            isEnabled: true
        })
    });
}

export const GetAudienceDetails = (id: string) => {
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v2/audiences/${id}/details`,
        body: JSON.stringify({})
    });
}

export const GetAllAudiences = (pageNumber: number = 1, pageAmount?: number, filters: Partial<AudienceFilterValues> = {}) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v2/audiences?pageAmount=${rowsPerPage}&pageNumber=${pageNumber}`,
        body: JSON.stringify(filters)
    });
}

export const GetDisabledAudiences = (pageNumber: number = 1, pageAmount?: number, filters: Partial<AudienceFilterValues> = {}) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v2/audiences?pageAmount=${rowsPerPage}&pageNumber=${pageNumber}`,
        body: JSON.stringify({
            ...filters,
            states: ["disabled"]
        })
    });
}

export const GetEnabledAudiences = (pageNumber: number = 1, pageAmount?: number, filters: Partial<AudienceFilterValues> = {}) => {
    let rowsPerPage = pageAmount || cookie.getRowsPerPage();

    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v2/audiences?pageAmount=${rowsPerPage}&pageNumber=${pageNumber}`,
        body: JSON.stringify({
            ...filters,
            states: ["enabled"]
        })
    });
}

export const SaveAudience = (id: string, audience: DraftAudience) => {
    const body = { ...audience, groups: audience.groups.map(group => group.groupId), users: audience.users.map(user => user.userId) };
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v2/audiences/${id}`,
        body: JSON.stringify(body)
    });
}

//Fetch total unique users in a set of audiences with no overlap
//Input: array of audience ids
//Output: int containing the number of unique users found
export const GetUniqueUsers = (audienceIds: string[], grabAll: boolean) => {
    const body = { audienceIds, "useAllAudiences": grabAll };
    return actions.NetworkRequest({
        headers: { "content-type": "application/json" },
        method: "POST",
        url: `/{tenant}/adminapi/v1/audiences/uniqueUsers`,
        body: JSON.stringify(body)
    })
}
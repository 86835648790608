import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";

import TenantLink from "modules/common/components/tenantLink";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    breadcrumb: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 10,
      "& a": {
        "&:hover": {
          textDecoration: "none"
        }
      },
      "& > a": {
        display: "inline-block",
        marginTop: 5
      }
    },
    crumbs: {
      display: "flex",
      alignItems: "center",
      fontWeight: 600,
      "& > div": {
        display: "inline-flex",
        alignItems: "center"
      },
      "& a": {
        color: "#adb7c1"
      }
    },
    separator: {
      color: "#adb7c1",
      padding: "0 8px"
    },
    title: {
      color: "#adb7c1"
    },
    titleLink: {
      color: "#adb7c1",
      cursor: "pointer"
    },
    backItem: {
      fontWeight: 400,
      paddingLeft: 0,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    breadcrumbOptions: {
      "& button, & div": {
        marginRight: 8,
        "&:last-child": {
          marginRight: 0
        }
      },
      "&.flex": {
        display: "flex",
        alignItems: "center"
      },
    }
  })
);

const BackItem: React.FunctionComponent<BackItem> = props => {
  const classes = useStyles();

  const getButton = (onClick?: () => void): JSX.Element =>
    <Button variant="text" color="primary" disableRipple startIcon={<ChevronLeftIcon />} className={classes.backItem} onClick={onClick}>
        <span style={props.textStyle}>{props.title}</span>
    </Button>;

  if (props.link)
    return <TenantLink to={props.link}>{getButton()}</TenantLink>;
  else if (props.onClick)
    return getButton(props.onClick);
  else
    return getButton();
}

const Breadcrumb: React.FunctionComponent<ComponentProps> = props => {
  const classes = useStyles();
  const getLeftComponent = () => {
    return <div>
        <div className={classes.crumbs}>
            {props.items.map((item, index) => (
                <div key={item.title ? item.title : index}>
                {item.link
                    ? <TenantLink to={item.link}>
                        <Typography variant="h2" className={classes.title}>{item.title}</Typography>
                    </TenantLink>
                    : item.onClick
                    ? <Typography variant="h2" onClick={item.onClick} className={classes.titleLink}>{item.title}</Typography>
                    : item.handleLinkByParent
                        ? <Link to="#" onClick={item.handleLinkByParent}>
                            <Typography variant="h2">{item.title}</Typography>
                        </Link>
                        : <Typography variant="h2" className={index === 0 ? classes.title : ""}>{item.title}</Typography>
                }
                {index < props.items.length - 1 ? <div className={classes.separator}>/</div> : null}
                </div>
            ))}
        </div>
        {!!props.backItem && <BackItem {...props.backItem} />}
    </div>
  }

  return (
    <div className={classes.breadcrumb}>
      <Box flexDirection={"row"} display={"flex"}>
        {getLeftComponent()}
        {props.middleComponent}
      </Box>
      {props.rightComponent &&
        <div className={`${classes.breadcrumbOptions} ${props.isFlex ? "flex" : ""}`}>
            {props.rightComponent}
        </div>
      }
    </div>
  );
}

interface BreadcrumbItem {
  title: string;
  link?: string;
  handleLinkByParent?: () => void;
  onClick?: () => void;
}

interface BackItem {
  title: string;
  link?: string;
  onClick?: () => void;
  textStyle?: CSSProperties;
}

interface ComponentProps {
  items: BreadcrumbItem[];
  backItem?: BackItem;
  middleComponent?: JSX.Element;
  rightComponent?: JSX.Element;
  isFlex?: boolean;
}

export default Breadcrumb;
export { BackItem };

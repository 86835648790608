import { Divider, List } from "@mui/material";
import MoreOptionsIconButton from "modules/common/components/buttons/moreOptionsIconButton";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";
import React from "react";
import { IEmailListItem } from "../models";

interface IEmailOptionsProps {
    email: IEmailListItem;
    onDownloadEmailActivity?: (email: IEmailListItem) => void;
    onEditEmail?: (email: IEmailListItem) => void;
    onPreviewEmail: (email: IEmailListItem) => void;
    onDuplicateEmail: (email: IEmailListItem) => void;
    onDeleteEmail?: (email: IEmailListItem) => void;
    onUnsendEmail?: (email: IEmailListItem) => void;
}

const EmailOptions: React.FC<IEmailOptionsProps> = ({
    email,
    onDownloadEmailActivity,
    onEditEmail,
    onPreviewEmail,
    onDuplicateEmail,
    onDeleteEmail,
    onUnsendEmail
}) => {
    return <MoreOptionsIconButton>
        <List disablePadding>
            {
                email.status === "draft"
                ? onEditEmail && <MoreOptionsItem
                    text="Continue editing"
                    onClick={() => onEditEmail(email)}
                />
                :  email.status === "scheduled" 
                    ? onUnsendEmail && 
                    <MoreOptionsItem 
                        textStyle={{ color: "#B72026" }}
                        text="Cancel send"
                        onClick={() => onUnsendEmail(email)}
                    />
                    : onDownloadEmailActivity && 
                    <MoreOptionsItem
                        text="Download email activity"
                        onClick={() => onDownloadEmailActivity(email)}
                    />
            }
            <Divider light />
            <MoreOptionsItem
                text="Preview"
                onClick={() => onPreviewEmail(email)}
            />
            <MoreOptionsItem
                text="Duplicate"
                onClick={() => onDuplicateEmail(email)}
            />
            {
                onDeleteEmail &&
                <MoreOptionsItem
                    text="Delete"
                    textStyle={{ color: "#B72026" }}
                    onClick={() => onDeleteEmail(email)}
                />
            }
        </List>
    </MoreOptionsIconButton>;
};

export default EmailOptions;

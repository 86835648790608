import React, { useEffect, useState } from "react";
import DownloadRecipientsButton from "./downloadRecipientsButton";
import LoadingCircle from "modules/common/components/loadingCircle";
import { useDispatch, useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { emailsSlice } from "modules/emails/reducer";
import { audiencesApi } from "api/instances";
import { useDownloadRecipients } from "modules/common/hooks/useDownloadRecipients";
import useIsMounted from "modules/common/hooks/useIsMounted";

interface IRecipientCountDownloadProps {
    onSaveEmail: () => void;
    onSuccessMessage: (message: string) => void;
    onErrorMessage: (message: string) => void;
}

const RecipientCountDownload: React.FC<IRecipientCountDownloadProps> = ({
    onSaveEmail,
    onSuccessMessage,
    onErrorMessage,
}) => {
    const [audienceCount, setAudienceCount] = useState(0);
    const [isFetchingAudienceCount, setIsFetchingAudienceCount] = useState(false);
    const { email, changedSinceLastSaved } = useSelector((state: GlobalApplicationState) => state.emails.editor);
    const { isDownloadingRecipients, downloadRecipients } = useDownloadRecipients({onSuccessMessage, onErrorMessage, emailId: email.id});
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    const setShouldDownloadRecipientsList = (shouldDownload: boolean) => 
        dispatch({ type: emailsSlice.actions.SET_SHOULD_DOWNLOAD_RECIPIENTS_LIST, payload: { shouldDownload }});

    const onClickDownloadRecipientsReport = async () => {
        // if a user clicks on downloading the recipients report and the editor isn't
        // saved (either it's a new email with email.id == "" or there have been changes)
        // then we save the email first and then perform the action.
        // in the case of no email id, we will save, redirect, and then on the first render we 
        // will start the download
        if (!email.id || changedSinceLastSaved) {
            onSaveEmail();
            setShouldDownloadRecipientsList(true);
        }
        else
            await downloadRecipients();
    }

    useEffect(() => {
        const getAndSetAudienceCount = async () => {
            setIsFetchingAudienceCount(true);
            try {
                const { data } = await audiencesApi.getUniqueUsers(email.audiences.map(a => a.id), email.sendToAllUsers);
                if (isMounted()) setAudienceCount(data);
            }
            catch {
                if (isMounted()) setAudienceCount(0);
            }
            finally {
                if (isMounted()) setIsFetchingAudienceCount(false);
            }
        }
        if (isMounted()) {
            if (email.audiences.length > 0 || email.sendToAllUsers) {
                getAndSetAudienceCount();
            }
            else if (email.audiences.length === 0 && audienceCount !== 0) {
                setAudienceCount(0);
            }
        }

    }, [email.audiences, email.sendToAllUsers, isMounted]);

    return <div className="recipient-button-container">
        <span className="recipient-count">{isFetchingAudienceCount ? <LoadingCircle size="16px"/> : audienceCount} employee{audienceCount === 1 ? "" : "s"}</span>
        <DownloadRecipientsButton
            isDownloadingRecipients={isDownloadingRecipients} 
            audienceCount={audienceCount} 
            onClick={onClickDownloadRecipientsReport}
        />
    </div>;
};

export default RecipientCountDownload;

import React, { useState } from "react";
import { EmailListingPageId, EmailSortStyle, IEmail, IEmailFilters, IEmailListingPage, IEmailListItem } from "../models";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Paging from "modules/common/components/paging";
import ManagementEmptyResults from "modules/common/components/managementEmptyResults";
import cookie, { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";
import { push } from "react-router-redux";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { emailsApi } from "api/instances";
import EmailOptions from "./emailOptions";
import moment from "moment";
import Loading from "modules/common/components/loading";
import TabContent from "pages/common/tabContent";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import EmailFilters from "./emailFilters";
import "../styles/emailsList.sass";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import Preview, { PreviewType } from "modules/common/components/authoring/dialogs/preview";
import EmailContentView from "./emailContentView";

interface IEmailsListProps {
    show: boolean;
    page: IEmailListingPage;
    fetchPage: (filters?: IEmailFilters, pageNumber?: number, pageAmount?: number) => void;
    fetchAllPages: () => void;
    filters: IEmailFilters;
    onUpdateFilters: (newFilters: IEmailFilters) => void;
    defaultEmailFilterValues: IEmailFilters;
    onInformationMessage: (message: string) => void;
    onErrorMessage: (errorMsg: string) => void;
    onDownloadEmailActivity: (emailId: string) => void;
    activeLcid: string;
}

interface HeaderCell {
    id: string;
    label: string;
    ascValue?: EmailSortStyle;
    descValue?: EmailSortStyle;
    clickable?: boolean;
}

const EmailsList: React.FC<PropsWithRedux> = ({
    show,
    page,
    fetchPage,
    fetchAllPages,
    filters,
    onUpdateFilters,
    defaultEmailFilterValues,
    tenantId,
    redirectTo,
    onInformationMessage,
    onErrorMessage,
    onDownloadEmailActivity,
    activeLcid
}) => {
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [emailToPreview, setEmailToPreview] = useState<IEmail | undefined>();
    const [showDeleteEmailDialog, setShowDeleteEmailDialog] = useState(false);
    const [showUnsendEmailDialog, setShowUnsendEmailDialog] = useState(false);
    const [emailIdToDelete, setEmailIdToDelete] = useState("");
    const [emailIdToUnsend, setEmailIdToUnsend] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUnsending, setIsUnsending] = useState(false);

    const showIsLoading = page.isFetching || isDeleting || isUnsending;
    const hasFiltersApplied = filters.textToSearch !== defaultEmailFilterValues.textToSearch;

    const getPublishedTimeTabLabel = () => {
        switch (page.id) {
            case EmailListingPageId.DRAFTS:
                return "Last modified on";
            case EmailListingPageId.SCHEDULED:
                return "Send on";
            case EmailListingPageId.SENT:
                return "Sent on";
        } 
    }

    const getHeader = () => {
        let header: HeaderCell[] = [
            {
                id: "title",
                label: "Subject",
                ascValue: EmailSortStyle.subjectAsc,
                descValue: EmailSortStyle.subjectDesc,
                clickable: true
            }
        ];

        header.push({ id: "options", label: "" });

        header.push({
            id: "publishedTime",
            label: getPublishedTimeTabLabel(),
            ascValue: page.id === EmailListingPageId.DRAFTS ? EmailSortStyle.lastModifiedTimeAsc : EmailSortStyle.sentTimeAsc,
            descValue: page.id === EmailListingPageId.DRAFTS ? EmailSortStyle.lastModifiedTimeDesc : EmailSortStyle.sentTimeDesc,
            clickable: true
        });

        header.push({
            id: "author",
            label: page.id === EmailListingPageId.DRAFTS ? "Last modified by" : "Published by",
            ascValue: page.id === EmailListingPageId.DRAFTS ? EmailSortStyle.lastModifiedByAsc : EmailSortStyle.sentByAsc,
            descValue: page.id === EmailListingPageId.DRAFTS ? EmailSortStyle.lastModifiedByDesc : EmailSortStyle.sentByDesc,
            clickable: true
        });

        header.push({
            id: "fromAddress",
            label: "From address",
            ascValue: EmailSortStyle.fromSenderAsc,
            descValue: EmailSortStyle.fromSenderDesc,
            clickable: true
        });

        return header;
    }

    const onClickHeader = (descValue?: EmailSortStyle, ascValue?: EmailSortStyle) => {
        let updatedFilters = { ...filters };

        if (descValue === undefined || ascValue === undefined) {
            return;
        }

        if (filters.sortBy === descValue) {
            updatedFilters = { ...updatedFilters, sortBy: ascValue };
        } else {
            updatedFilters = { ...updatedFilters, sortBy: descValue };
        }

        onChangeFilters(updatedFilters);
    }

    const onChangeFilters = (newFilters: IEmailFilters) => {
        const rowsPerPage = cookie.getRowsPerPage(ROWS_PER_PAGE_COOKIE_NAMES.EMAILS, 25);

        onUpdateFilters(newFilters);
        onChangePage(0, rowsPerPage, newFilters);
    }

    const onChangePage = (page: number,  rowsPerPage: number, filters: IEmailFilters) => {
        fetchPage(filters, page + 1, rowsPerPage);
    }

    const handleDraftEmailClick = (email: IEmailListItem) => redirectTo("/" + tenantId + "/admin/emails/edit/" + email.id);

    const onPreviewEmail = async (emailToDisplay: IEmailListItem) => {
        setIsLoadingPreview(true);
        
        try {
            const { data } = await emailsApi.getEmail(emailToDisplay.id);
            setEmailToPreview(data);
        } catch (error) {
            setEmailToPreview(undefined);
            onErrorMessage("There was an error with your request. Please try again later.");
        } finally {
            setIsLoadingPreview(false);
        }
    }

    const formatTime = (time?: string): string => {
        if (time) {
            return moment(time).format("MMM D, YYYY h:mmA");
        }
        return "-";
    }

    const onDeleteEmail = (email: IEmailListItem) => {
        setEmailIdToDelete(email.id);
        setShowDeleteEmailDialog(true);
    }

    const deleteEmail = async () => {
        setShowDeleteEmailDialog(false);
        setIsDeleting(true);
        try {
            await emailsApi.deleteEmail(emailIdToDelete);
            fetchPage();
            onInformationMessage("Email deleted.");
        }
        catch {
            onErrorMessage("There was an error deleting the email. Please try again later.");
        }
        finally {
            resetDeleteEmailStates();
        }
    }

    const resetDeleteEmailStates = () => {
        setShowDeleteEmailDialog(false);
        setEmailIdToDelete("");
        setIsDeleting(false);
    }

    const onUnsendEmail = (email: IEmailListItem) => {
        setEmailIdToUnsend(email.id);
        setShowUnsendEmailDialog(true);
    }

    const unsendEmail = async () => {
        setShowUnsendEmailDialog(false);
        try {
            setIsUnsending(true);
            await emailsApi.cancelSend(emailIdToUnsend);
            fetchAllPages();
        }
        catch {
            onErrorMessage("There was an error with cancelling the email. Please try again later");
        }
        finally {
            resetUnsendEmailStates();
        }
    }

    const resetUnsendEmailStates = () => {
        setShowUnsendEmailDialog(false);
        setEmailIdToUnsend("");
        setIsUnsending(false);
    }
    
    const onDuplicateEmail = async (email: IEmailListItem) => {
        try {
            const { data } = await emailsApi.cloneEmail(email.id);
            redirectTo("/" + tenantId + "/admin/emails/edit/" + data);
        }
        catch { 
            onErrorMessage("There was an error with duplicating. Please try again later.");
        }
    }

    if (!show) return <></>;
    else if (showIsLoading) return <div style={{ paddingTop: "60px" }}><Loading /></div>;
    else if (!page.emails?.length) 
        return <ManagementEmptyResults 
            searchType={"Emails"} 
            hasFilters={hasFiltersApplied} 
            height={hasFiltersApplied ? "63vh" : "80vh"}
        />;
    else return <React.Fragment>
        <TabContent>
            <EmailFilters
                filters={filters}
                onChangeFilters={onChangeFilters}
                onClearFilters={() => onChangeFilters(defaultEmailFilterValues)}
            />
            { hasFiltersApplied && <Typography variant="h2" className="filter-results">Results</Typography> }
            <TableContainer>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            {
                                getHeader().map((headerCell) => (
                                    <TableCell
                                        key={headerCell.id}
                                        align={headerCell.id === "title" ? "left" : "center"}
                                        padding={headerCell.id === "title" ? "none" : "normal"}
                                        onClick={() => onClickHeader(headerCell.descValue, headerCell.ascValue)}
                                    >
                                        <div style={{
                                            paddingBottom: filters.sortBy === headerCell.ascValue || filters.sortBy === headerCell.descValue ?
                                                "8px" : "0px", cursor: headerCell.clickable ? "pointer" : "",
                                            marginLeft: headerCell.id === "title" ? 10 : 0
                                        }}
                                        >
                                            {headerCell.label}
                                            {filters.sortBy === headerCell.ascValue && <ArrowUpward htmlColor="#7A7A7A" style={{ position: "relative", top: "8px", paddingLeft: "2px" }} />}
                                            {filters.sortBy === headerCell.descValue && <ArrowDownward htmlColor="#7A7A7A" style={{ position: "relative", top: "8px", paddingLeft: "2px" }} />}
                                        </div>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            page.emails.map(email => 
                                <TableRow
                                    hover
                                    onClick={() => onPreviewEmail(email)}
                                    tabIndex={-1}
                                    key={email.id}
                                    style={{ cursor: "pointer" }}
                                >
                                    <TableCell component="th" scope="row" padding="none">
                                        <span className="email-subject-row-value">{email.subject.filter(s => s.lcid === activeLcid)[0]?.text ?? ""}</span>
                                    </TableCell>
                                    <TableCell >
                                        <EmailOptions 
                                            email={email}
                                            onDownloadEmailActivity={email.status !== "draft" ? (email) => onDownloadEmailActivity(email.id) : undefined}
                                            onEditEmail={email.status === "draft" ? handleDraftEmailClick : undefined}
                                            onDuplicateEmail={onDuplicateEmail}
                                            onPreviewEmail={onPreviewEmail}
                                            onDeleteEmail={email.status === "draft" || email.status === "scheduled" ? onDeleteEmail : undefined}
                                            onUnsendEmail={email.status === "scheduled" ? onUnsendEmail : undefined}
                                        />
                                    </TableCell>
                                    <TableCell align="center">{formatTime(email.status === "draft" ? email.lastModification?.modifiedTime : email.sentTime)}</TableCell>
                                    <TableCell align="center">{(email.status === "draft" ? email.lastModification?.modifiedBy.name : email.sentBy?.name) ?? ""}</TableCell>
                                    <TableCell align="center">{email.fromSender?.email ?? ""}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Paging
                currentPage={page.currentPage}
                items={page.emails}
                totalItems={page.totalEmails}
                totalPages={page.totalPages}
                onChangePage={(page, rowsPerPage) => onChangePage(page, rowsPerPage, filters)}
                rowsCookieName={ROWS_PER_PAGE_COOKIE_NAMES.EMAILS}
            />
            <ConfirmDialog
                title="Delete Email"
                open={showDeleteEmailDialog}
                confirmLabel="DELETE"
                confirmButtonProps={{sx: {backgroundColor: '#dc3838', color: 'white'}}}
                denyLabel="CANCEL"
                onConfirm={deleteEmail}
                onDeny={resetDeleteEmailStates}
                onClose={resetDeleteEmailStates}
            >
                You are about to permanently delete this email. This action cannot be undone.
                <br /><br />
                Are you sure you want to proceed?
            </ConfirmDialog>
            <ConfirmDialog
                title="Cancel Send"
                open={showUnsendEmailDialog}
                confirmLabel="CANCEL SEND"
                confirmButtonProps={{sx: {backgroundColor: '#dc3838', color: 'white'}}}
                denyLabel="CANCEL"
                onConfirm={unsendEmail}
                onDeny={resetUnsendEmailStates}
                onClose={resetUnsendEmailStates}
            >
                You are about to cancel sending this email. It will be moved back to drafts.
                <br /><br />
                Are you sure you want to proceed?
            </ConfirmDialog>
            {
                emailToPreview &&
                <Preview
                    onClose={() => setEmailToPreview(undefined)}
                    open={!!emailToPreview}
                    previews={[{ type: PreviewType.Plain, component: <EmailContentView email={emailToPreview} activeLcid={activeLcid}/>, label: "DESKTOP" }]}
                />
            }
            <LoadingOverlay absolute={true} show={isLoadingPreview} />
        </TabContent>
    </React.Fragment>;
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IEmailsListProps) => ({
        ...ownProps,
        tenantId: state.tenant.id,
    }),
    {
        redirectTo: push
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EmailsList);

import { emailsApi } from "api/instances";
import { GlobalApplicationState } from "globalApplicationState";
import PublishedContentDialog from "modules/common/components/authoring/dialogs/publishedContentDialog";
import { useRetryableRequest } from "modules/common/hooks/useRetryableRequest";
import { ContentType } from "modules/common/models";
import React from "react";
import { useSelector } from "react-redux";
import { IEmail } from "../models";

interface ISentEmailDialogProps {}

const MAX_RETRY_COUNT = 3;

const SentEmailDialog: React.FC<ISentEmailDialogProps> = ({}) => {
    const { emailId, expectedSentTime } = useSelector((state: GlobalApplicationState) => state.emails.sentConfirmation);
    const fetchEmail = async () => await emailsApi.getEmail(emailId);

    const { response, isFailed } = useRetryableRequest<IEmail>({
        asyncRequest: fetchEmail,
        maxRetry: MAX_RETRY_COUNT,
        requesting: !!emailId
    });

    return <PublishedContentDialog
        content={ContentType.Email}
        expectedPublishTime={expectedSentTime}
        contentId={response?.id}
        hideFeedRedirect={isFailed}
    />;
};

export default SentEmailDialog;

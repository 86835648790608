import React from "react";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button } from "@mui/material";
import LoadingCircle from "modules/common/components/loadingCircle";

import "../../styles/downloadRecipientsButton.sass";

interface IDownloadRecipientsButtonProps {
    audienceCount: number;
    isDownloadingRecipients: boolean;
    onClick: () => void;
    loadingCirleOnRight?: boolean;
}

const DownloadRecipientsButton: React.FC<IDownloadRecipientsButtonProps> = ({
    audienceCount,
    isDownloadingRecipients,
    onClick,
    loadingCirleOnRight = false
}) => {
    return <div className="download-recipients-button-container">
        { !loadingCirleOnRight && isDownloadingRecipients && <LoadingCircle size="16px"/>}
        <Button
            startIcon={<SaveAltIcon />}  
            onClick={onClick}
            variant="text"
            color="primary"
            sx={{ paddingRight: 0 }}
            disabled={audienceCount === 0 || isDownloadingRecipients}
        >
            Download recipients
        </Button>
        { loadingCirleOnRight && isDownloadingRecipients && <LoadingCircle size="16px"/>}
    </div>;
};

export default DownloadRecipientsButton;

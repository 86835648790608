import { IdNamePair, ITranslatedText } from "modules/common/models";

export interface IEmailListingPage extends IEmailListing {
    id: EmailListingPageId;
    isFetching: boolean;
}

export interface IEmailListing {
    currentPage: number;
    emails: IEmailListItem[];
    totalPages: number;
    totalEmails: number;
}

export enum EmailListingPageId {
    DRAFTS = "drafts",
    SCHEDULED = "scheduled",
    SENT = "sent"
}

export interface IEmailFilters {
    textToSearch: string;
    status: EmailStatus;
    createdByOnlyMe: boolean;
    sortBy: EmailSortStyle;
}

export enum EmailSortStyle {
    subjectAsc = "subjectAsc",
    lastModifiedTimeAsc = "lastModifiedTimeAsc",
    lastModifiedByAsc = "lastModifiedByAsc",
    fromSenderAsc = "fromSenderAsc",
    sentTimeAsc = "sentTimeAsc",
    sentByAsc = "sentByAsc",
    subjectDesc = "subjectDesc",
    lastModifiedTimeDesc = "lastModifiedTimeDesc",
    lastModifiedByDesc = "lastModifiedByDesc",
    fromSenderDesc = "fromSenderDesc",
    sentTimeDesc = "sentTimeDesc",
    sentByDesc = "sentByDesc",
}

const DEFAULT_EMAIL_FILTERS = {
    textToSearch: "",
    createdByOnlyMe: false,
    sortBy: EmailSortStyle.lastModifiedTimeDesc
}

export const DEFAULT_DRAFT_EMAIL_FILTERS: IEmailFilters = {
    ...DEFAULT_EMAIL_FILTERS,
    status: "draft",
}

export const DEFAULT_SCHEDULED_EMAIL_FILTERS: IEmailFilters = {
    ...DEFAULT_EMAIL_FILTERS,
    sortBy: EmailSortStyle.sentTimeDesc,
    status: "scheduled",
}

export const DEFAULT_SENT_EMAIL_FILTERS: IEmailFilters = {
    ...DEFAULT_EMAIL_FILTERS,
    sortBy: EmailSortStyle.sentTimeDesc,
    status: "sent",
}

export type EmailStatus = "draft" | "scheduled" | "sent";

export interface IModification {
    modifiedTime: string;
    modifiedBy: IdNamePair<string, string>;
}

export interface IEmailListItem {
    id: string;
    tenantId: string;
    createdBy: IdNamePair<string, string>;
    createdTime: string;
    sentBy?: IdNamePair<string, string>;
    sentTime?: string;
    lastModification: IModification;
    subject: ITranslatedText[];
    status: EmailStatus;
    fromSender: {
        name: string,
        email: string
    };
}

export interface IEmail {
    id: string;
    tenantId: string;
    sentBy?: IdNamePair<string, string>;
    sentTime?: string;
    subject: ITranslatedText[];
    body: ITranslatedText[];
    status: EmailStatus;
    audiences: IdNamePair<string, string>[];
    sendToAllUsers: boolean;
    fromSender: {
        name: string,
        email: string
    };
    replyAddress?: string;
}

export interface IEmailInput {
    subject: ITranslatedText[];
    body: ITranslatedText[];
    audiences: string[];
    sendToAllUsers: boolean;
    fromSender: {
        name: string;
        email: string;
    };
    replyAddress?: string
    sentTime?: string;
}

export interface IEmailSentConfirmation {
    shouldShowDialog: boolean,
    expectedSentTime: string,
    emailId: string,
}

export interface IEmailsState {
    draftEmailsList: IEmailListingPage;
    scheduledEmailsList: IEmailListingPage;
    sentEmailsList: IEmailListingPage;
    editor: {
        email: IEmail;
        changedSinceLastSaved: boolean;
        isFetching: boolean;
        isSaving: boolean;
        shouldDownloadRecipientsList: boolean;
        shouldSendTestEmails: boolean;
        testEmails: string[];
        shouldOpenOneLastCheck: boolean;
    }
    successMessage: string,
    errorMessage: string,
    sentConfirmation: IEmailSentConfirmation;
    redirectUrl?: string;
}

export interface IPersonalizationToken {
    title: string;
    description: string;
    value: PersonalizationTokenValue;
}

export enum PersonalizationTokenValue {
    UserFirstName = "FirstName",
    UserLastName = "LastName"
}

export const MERGE_TAG_PREFIX = "{{";

export const MERGE_TAG_SUFFIX = "}}";

export const EMAIL_PERSONALIZATION_TOKENS: IPersonalizationToken[] = [
    { title: "First Name", description: "This will insert the employee's first name", value: PersonalizationTokenValue.UserFirstName }, 
    { title: "Last Name",  description: "This will insert the employee's last name", value: PersonalizationTokenValue.UserLastName }
];
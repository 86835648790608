import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { AttachedFile } from "modules/gallery/models";
import { CroppableImage } from "modules/common/components/authoring/models";
import HeroBannerEditor from "./heroBanner/heroBannerEditor";
import PostType from "modules/posts/components/post-creation/components/postType";
import { Post, Tag } from "modules/posts/models";
import TopicMultiselectAndChips from "../forms/inputs/topicMultiselectAndChips";
import { GlobalApplicationState } from "globalApplicationState";
import Disclaimer from "../disclaimer";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { IdNamePair } from "modules/common/models";
import useIsMounted from "modules/common/hooks/useIsMounted";
import ChipList, { IChipListItem } from "../chipList";
import IconTextField from "../forms/inputs/iconTextInput";
import { isPostEmailValid } from "modules/posts/components/post-creation/validation";
import FileAttachments from "./fileAttachments";
import { tagsApi } from "api/instances";
import { TenantSettingsTag, TenantSettingsTagGroup, MultiSelectTopic } from "modules/settings";
import { Divider } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email'

import "./styles/detailsEditor.sass";
import { mentionCheck } from "utils/regexUserValidation";

interface IDetailsEditorProps {
    hasPublished: boolean;
    post: Partial<Post>;
    onChangeAttachedContent: (selectedImages: AttachedFile[]) => void;
    onChangeImage: (image: CroppableImage | undefined) => void;
    onChangePostType: (postType: string) => void;
    onChangeTopics: (topics: Tag[]) => void;
    onChangeAuthor: (author: string) => void;
    onChangeAuthorEmail: (authorEmail: string) => void;
    onChangeFileAttachements: (fileAttachments: AttachedFile[]) => void;
    isContributor: boolean;
}

interface AuthorContact {
    name: string,
    email: string
}

const defaultAuthorContact: AuthorContact = {
    name: "",
    email: ""
}

const DetailsEditor: React.FunctionComponent<PropsWithRedux> = ({
    post,
    hasPublished,
    topicGroups,
    onChangeTopics,
    onChangePostType,
    onChangeAttachedContent,
    onChangeImage,
    onChangeAuthor,
    onChangeAuthorEmail,
    onChangeFileAttachements,
    isContributor,
}) => {
    const { translatedContent } = post;
    const [hasMention, setHasMention] = useState<boolean>();
    const [topicAudiences, setTopicAudiences] = useState<IdNamePair<string, string>[]>([]);
    const [authorContact, setAuthorContact] = useState<AuthorContact>(defaultAuthorContact);
    const isMounted = useIsMounted();

    // for old sparrow accounts with no email stored, allow to edit email address
    const canEditAuthorName = !isContributor;
    const canEditAuthorEmail = canEditAuthorName || post.authorEmail === undefined || post.authorEmail === "";

    useEffect(() => {
        const toTest = JSON.stringify(translatedContent);
        const result = mentionCheck.test(toTest);

        if (result !== hasMention)
            setHasMention(result);

    }, [translatedContent, hasMention]);

    useEffect(() => {
        const getAudiences = async () => {
            let newAudiences = [];
            try {
                if (post.tags && post.tags.length > 0)
                    newAudiences = await tagsApi.getAssociatedAudiences(post.tags.map((tag: Tag) => tag.id));
            } catch (error) {
            } finally {
                if (isMounted())
                    setTopicAudiences(newAudiences);
            }
        }

        getAudiences();
    }, [post.tags, isMounted]);

    /**
     * Put hero and attached content in one array
     */
    const getImages = (): AttachedFile[] => {
        const { image, attachedContent } = post;

        if (!image || !image.id) return [];

        let consolidatedImages: AttachedFile[] = [
            {
                ...image,
                fileName: "",
                fileType: "image",
                fileExtension: "",
                blobId: image.id,
                fileUrl: image.url,
                ospreyId: image.id,
            }
        ];

        if (attachedContent)
            consolidatedImages = [...consolidatedImages, ...attachedContent];

        return consolidatedImages;
    }

    const onEditHeroImage = (image: CroppableImage | undefined) => {
        onChangeImage(image);
    }

    const onEditAttachedContent = (attached: AttachedFile[]) => {
        onChangeAttachedContent(attached);
    }

    const onEditTopics = (topics: MultiSelectTopic[]) => {
        onChangeTopics([...topics]);
    }

    const onEditAuthor = (event: React.FocusEvent<HTMLInputElement>) => {
        const newAuthorName = event.currentTarget.value
        if (authorContact.name !== newAuthorName) {
            onChangeAuthor(newAuthorName);
        }
    }

    const onEditAuthorEmail = (event: React.FocusEvent<HTMLInputElement>) => {
        const newEmail = event.currentTarget.value
        if (authorContact.email !== newEmail) {
            onChangeAuthorEmail(newEmail);
        }
    }

    const onFocusAuthor = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.value
        setAuthorContact({ ...authorContact, name })
    }

    const onFocusAuthorEmail = (event: React.FocusEvent<HTMLInputElement>) => {
        const email = event.target.value
        setAuthorContact({ ...authorContact, email })
    }

    const getSelectedTopics = (): MultiSelectTopic[] | undefined => {
        let allTopics = topicGroups.flatMap((group: TenantSettingsTagGroup) => group.tags);

        return allTopics
            .filter((topic: TenantSettingsTag) => (post.tags?.map((postTag: Tag) => postTag.id) || []).includes(topic.id))
            .map((topic) => ({
                ...topic
            }));
    }

    const showAccessibleToAllUsersDisclaimer = (): JSX.Element => {
        const postTopics = getSelectedTopics();

        let hasRestricted = postTopics?.some((topic: MultiSelectTopic) => topic.restricted);
        let hasNonRestricted = postTopics?.some((topic: MultiSelectTopic) => !topic.restricted);

        return hasRestricted && hasNonRestricted
            ? <Disclaimer
                closable
                text={"Selecting both restricted and non-restricted topics will make this post searchable and accessible to all users. Select only a private topic to restrict it's visibility to the associated audiences."}
                icon={<InfoOutlinedIcon />}
                backgroundColor="#ffe9af"
            />
            : <></>;
    }

    const showMentionsAndRestrictedDisclaimer = (): JSX.Element => {
        let result = <></>;
        let postTopics = getSelectedTopics();

        let hasRestricted = postTopics?.some((topic: MultiSelectTopic) => topic.restricted);
        let hasNonRestricted = postTopics?.some((topic: MultiSelectTopic) => !topic.restricted);

        if (hasRestricted && !hasNonRestricted && hasMention)
            result = <Disclaimer
                containerStyle={{ marginTop: 10 }}
                icon={<InfoOutlinedIcon />}
                text={"@Mentioning someone in a restricted post will not send a notification to the user if they do not have access to the restricted topic."}
            />;

        return result;
    }

    const showPrivateToAudiencesDisclaimer = (): JSX.Element => {
        let result = <></>;
        let postTopics = getSelectedTopics();
        let hasRestricted = postTopics?.some((topic: MultiSelectTopic) => topic.restricted);

        if (hasRestricted) {
            let hasNonRestricted = postTopics?.some((topic: MultiSelectTopic) => !topic.restricted);
            result = hasNonRestricted
                ? <></>
                : <Disclaimer
                    containerStyle={{ marginTop: 10 }}
                    icon={<InfoOutlinedIcon />}
                    text={"This post is private to the following audiences."}
                />
        }

        return result;
    }

    /**
     * Get a list of audiences
     * - if post has no topics then render a default message
     * @returns
     */
    const getAudienceList = (): IChipListItem<string, string>[] => {
        let audiences: IChipListItem<string, string>[] = topicAudiences.map((chip: IdNamePair<string, string>) => ({ ...chip, disabled: true }));

        if (!post.tags || post.tags.length === 0)
            audiences = [{ id: "EMPTY", name: "Select at least 1 topic", disabled: true }];
        else if (post.tags && post.tags.length > 0 && topicAudiences.length === 0)
            audiences = [{ id: "ALL", name: "All users", disabled: true, labelStyle: { fontStyle: "italic" } }];

        return audiences;
    }

    return (
        <div className="details-container">
            <div style={{ paddingLeft: 20, paddingRight: 33, paddingTop: 21, paddingBottom: 10 }}>
                <HeroBannerEditor
                    images={getImages()}
                    onEditHeroImage={onEditHeroImage}
                    onEditAttachedContent={onEditAttachedContent}
                />
                <Divider style={{ color: "#dde1e5", margin: "8px 0 24px" }} />
                {
                    !isContributor &&
                    <PostType label="Post type" inline hasPublished={hasPublished} postType={post.postType || "Standard"} onChange={onChangePostType} />
                }
                <div className="detail-input-group" style={{
                    alignItems: post.tags && post.tags.length > 0 ? "flex-start" : "center"
                }}>
                    <label className="detail-label required">Topics</label>
                    <TopicMultiselectAndChips
                        containerClassName="detail-input"
                        selectedOptions={getSelectedTopics()}
                        onChange={onEditTopics}
                    />
                </div>
                {showMentionsAndRestrictedDisclaimer()}
                {showAccessibleToAllUsersDisclaimer()}
                {showPrivateToAudiencesDisclaimer()}
                <div className="detail-input-group">
                    <HoverText label="Audiences" labelContainerClassName="detail-label">
                        Shows you the targeted audiences when the topic you use is default, mandatory or restricted to specific audiences.
                    </HoverText>
                    {topicAudiences &&
                        <div className="detail-input">
                            <ChipList
                                items={getAudienceList()}
                                closable={false}
                                truncateAtIndex={4}
                            />
                        </div>}
                </div>
                <Divider className="authoring-divider" />
                {
                    canEditAuthorEmail &&
                    <>
                        <div className="detail-input-group" style={{ justifyContent: "space-between" }}>
                            <HoverText
                                label="Author"
                                labelClassName="required"
                                style={{
                                    marginTop: canEditAuthorName ? 7 : "auto",
                                    marginBottom: "auto"
                                }}
                            >
                                Change the {canEditAuthorName && 'name and'} email that appears on the post.
                            </HoverText>
                            <div style={{ flexDirection: "column" }}>
                                {
                                    canEditAuthorName &&
                                    <IconTextField
                                        value={post.author}
                                        onBlur={onEditAuthor}
                                        iconStart={<PersonIcon style={{ color: "#aaaaaa" }} />}
                                        variant="outlined"
                                        className="author-inputs"
                                        error={!post.author}
                                        characterCount
                                        maxCount={64}
                                        characterCountThreshold={50}
                                        onFocus={onFocusAuthor}
                                    />
                                }
                                <div style={{ width: "100%", textAlign: "right", marginTop: canEditAuthorName ? 7 : 0 }}>
                                    <IconTextField
                                        value={post.authorEmail}
                                        onBlur={onEditAuthorEmail}
                                        iconStart={<EmailIcon style={{ color: "#aaaaaa" }} />}
                                        variant="outlined"
                                        className="author-inputs"
                                        error={!isPostEmailValid(post)}
                                        errorMessage={"Enter a valid email address"}
                                        onFocus={onFocusAuthorEmail}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider className="authoring-divider"/>
                    </>
                }
                <FileAttachments
                    show
                    v2
                    fileAttachments={post.fileAttachments}
                    onChange={onChangeFileAttachements}
                    onRemove={() => onChangeFileAttachements([])}
                    footNoteStyle={{ textAlign: "left" }}
                />
            </div>
        </div>
    );
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: IDetailsEditorProps) => ({
        ...ownProps,
        topicGroups: state.settings.tenantSettings.tagGroups,
    }));

type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(DetailsEditor);

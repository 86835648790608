import React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { push } from "react-router-redux";
import { NewsletterDigest, NewsletterFilterValues, NewsletterListingPage, NewsletterStatus } from "../../models";
import moment from "moment";
import numeral from "numeral";

import Loading from "modules/common/components/loading";
import confirm from "utils/notyPopups";

import MoreNewsletterOptions from "../../dashboard/components/moreNewsletterOptions";
import NewsletterFilters from "./newsletterFilters";

import TabContent from "pages/common/tabContent";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ChipLabels from "modules/common/components/chips/chipLabels";

import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import Paging from "modules/common/components/paging";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { SortStyle } from "utils/managementUtils";
import Cookies from "js-cookie";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Button, Checkbox } from "@mui/material";
import TabContentToolbar from "pages/common/tabContentToolbar";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Audience } from "modules/audiences";
import ManagementEmptyResults from "modules/common/components/managementEmptyResults";
import { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";


interface HeaderCell {
  id: string;
  label: string | JSX.Element;
  ascValue?: SortStyle;
  descValue?: SortStyle;
  clickable?: boolean;
}

export type NewsletterListComponentTab = 'all' | 'enabled' | 'draft' | 'disabled';

class NewsletterList extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);

    this.state = {
      filters: { ...this.getDefaultFilterValues() },
      hasFiltersApplied: false,
      isPagedLoad: false,
      selectedItems: [],
      sortMethod: SortStyle.titleDesc,
    };
  }

  public componentDidMount() {
    moment.locale("en");
  }

  public render() {
    if (!this.props.show)
      return <React.Fragment></React.Fragment>;

    if (this.props.isFetching && !this.state.isPagedLoad)
      return <Loading />;
      
    return (
      <div className="newsletter-list">
        {this.getToolbar()}
        <TabContent>
          {this.getFilter()}
          {this.getList()}
        </TabContent>
      </div>
    );
  }

  private formatPublishedTime = (time: string | null): JSX.Element => {
    if (!!time) {
      return (
        <div>
          <span>{moment(time).format("MMM D, YYYY, h:mmA")}</span>
        </div>
      );
    }
    return <div>-</div>;
  }

  private formatState = (state: NewsletterStatus): JSX.Element => {
    if (state === "Disabled")
      return <span className="newsletter-state grey">{state}</span>;
    else if (state === "Draft")
      return <span className="newsletter-state yellow">{state}</span>;
    else
      return <span className="newsletter-state green">{state}</span>;
  }

  private formatTitle = (title: string): JSX.Element => {
    return (
      <div className="newsletter-title">
        <span>{title}</span>
      </div>
    );
  }
  
  private getDefaultFilterValues = (): Partial<NewsletterFilterValues> => {
    const filters: Partial<NewsletterFilterValues> = {
      textToSearch: "",
      sortType: SortStyle.titleDesc,
      tags: []
    };
    return filters;
  }

  private getFilter = (): JSX.Element => {
    return (
      <NewsletterFilters
        filters={this.state.filters}
        onChangeFilters={this.onChangeFilters}
        onClearFilters={this.onClearFilters}
        currentTab={this.props.tab}
        audiences={this.props.audiences}
      />
    );
  }

  private getHeader = (): HeaderCell[] => {
    const { tab } = this.props;

    let header: HeaderCell[] = [
      { id: "title", label: "Newsletter title", descValue: SortStyle.titleDesc, ascValue: SortStyle.titleAsc, clickable: true },
      { id: "options", label: "" },
    ];

    if (tab === "all")
      header.push({ 
        id: "status",  
        label: "State", 
        descValue: SortStyle.stateDesc, 
        ascValue: SortStyle.stateAsc,
        clickable: true
      });
    
    if (tab === "all" || tab === "enabled") {
      header.push({ 
        id: "lastIssueSentOn", 
        label: "Last sent on", 
        descValue: SortStyle.publishDesc, 
        ascValue: SortStyle.publishAsc,
        clickable: true
      });
      header.push({ 
        id: "nextIssueSendOn", 
        label: "Next queued for", 
        descValue: SortStyle.startDesc, 
        ascValue: SortStyle.startAsc,
        clickable: true
      });
    }
      
    if (tab === "draft") {
      header.push({ 
        id: "nextIssueSendOn", 
        label: "Next queued for", 
        descValue: SortStyle.startDesc, 
        ascValue: SortStyle.startAsc,
        clickable: true
      });
      header.push({ 
        id: "editedTime", 
        label: "Last modified on", 
        descValue: SortStyle.modifiedDesc, 
        ascValue: SortStyle.modifiedAsc,
        clickable: true
      });
      header.push({ 
        id: "audiences", 
        label: "Send to" });
    }

    if (tab === "disabled") {
      header.push({ 
        id: "lastIssueSentOn", 
        label: "Last sent on", 
        descValue: SortStyle.publishDesc, 
        ascValue: SortStyle.publishAsc,
        clickable: true
      });
      header.push({ 
        id: "editedTime", 
        label: "Last modified on", 
        descValue: SortStyle.modifiedDesc, 
        ascValue: SortStyle.modifiedAsc,
        clickable: true
      });
    }

    if (tab !== "draft") {
      header.push({ 
        id: "audiences", 
        label: "Sent to" 
      });
      header.push({ 
        id: "activity", 
        descValue: SortStyle.viewsDesc, 
        ascValue: SortStyle.viewsAsc,
        clickable: true,
        label: (
          <div className="header-view">
            <HoverText
              label="Subscribers"
            >
              <div>Employees who are subscribed and/or unsubscribed from the newsletter.</div>
            </HoverText>
          </div>
        ), 
      });  
    }
    
    return header;
  }

  private getList = (): JSX.Element => {
    const { page } = this.props;

    if (this.props.isFetching || !page)
      return <Loading />;

    if (!page.results.length)
      return (
        <ManagementEmptyResults searchType={"Newsletters"} hasFilters={this.state.hasFiltersApplied}/>
      );

    const rowCount = page.results.length || 10;
    const { selectedItems } = this.state;

    return (
      <React.Fragment>
        {this.state.hasFiltersApplied &&
          <Typography variant="h2" className="newsletter-results">Results</Typography>
        }
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedItems.length > 0 && selectedItems.length < rowCount}
                    checked={rowCount > 0 && selectedItems.length === rowCount}
                    onChange={this.onSelectAllNewsletters}
                    inputProps={{ "aria-label": "select all pages" }}
                  />
                </TableCell>
                {this.getHeader().map((headerCell) => {
                  if (headerCell.id === "options")
                    return <TableCell key={headerCell.id} padding="checkbox"></TableCell>

                  return (
                    <TableCell
                      key={headerCell.id}
                      align={headerCell.id === "title" ? "left" : "center"}
                      padding={headerCell.id === "options" ? "none" : "normal"}
                      onClick={() => this.onClickHeader(headerCell.descValue, headerCell.ascValue)}
                      style={{cursor: headerCell.clickable ? "pointer" : ""}}
                    >
                      {headerCell.label}
                      {this.state.sortMethod === headerCell.ascValue && <ArrowUpward htmlColor="#7A7A7A" style={{position: "relative", top: "8px", paddingLeft: "2px"}}/>}
                      {this.state.sortMethod === headerCell.descValue && <ArrowDownward htmlColor="#7A7A7A" style={{position: "relative", top: "8px", paddingLeft: "2px"}}/>}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {page.results
                .map((newsletter, index) => this.getListItem(newsletter, index))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          currentPage={page.currentPage}
          items={page.results}
          totalItems={page.totalItems}
          totalPages={page.totalPages}
          onChangePage={this.onChangePage}
          resetSelection={this.resetSelection}
        />
      </React.Fragment>
    );
  }

  private getListItem = (newsletter: NewsletterDigest, index: number): JSX.Element => {
    const labelId = `newsletter-list-checkbox-${index}`;
    const isNewsletterSelected = this.isNewsletterSelected(newsletter);

    return (
      <React.Fragment key={newsletter.id}>
        <TableRow
          key={newsletter.id}
          hover
          onClick={() => this.onSelectNewsletter(newsletter)}
          role="checkbox"
          tabIndex={-1}
          className="newsletter-list-item"
          selected={isNewsletterSelected}
          aria-checked={isNewsletterSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isNewsletterSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={(ev) => this.onCheckNewsletter(ev, newsletter)}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row">{this.formatTitle(newsletter.title)}</TableCell>
          <TableCell padding="checkbox">
            <MoreNewsletterOptions newsletter={newsletter} onCreateCopy={() => this.props.onCreateCopy(newsletter)} onNewsletterChanged={this.props.onNewsletterChanged} />
          </TableCell>
          {this.props.tab === "all" && <TableCell align="center">{this.formatState(newsletter.status)}</TableCell>}
          {(this.props.tab === "all" || this.props.tab === "enabled") &&
            <TableCell align="center">{this.formatPublishedTime(newsletter.lastIssueSentOn)}</TableCell>
          }
           {(this.props.tab === "all" || this.props.tab === "enabled") &&
            <TableCell align="center">{this.formatPublishedTime(newsletter.nextIssueSendOn)}</TableCell>
          }
          {(this.props.tab === "draft") &&
            <TableCell align="center">{this.formatPublishedTime(newsletter.nextIssueSendOn)}</TableCell>
          }
          {(this.props.tab === "disabled") &&
          <TableCell align="center">{this.formatPublishedTime(newsletter.lastIssueSentOn)}</TableCell>
          }
          {(this.props.tab === "disabled" || this.props.tab === "draft") &&
          <TableCell align="center">{this.formatPublishedTime(newsletter.editedTime)}</TableCell>
          }
          <TableCell align="center"><ChipLabels chips={newsletter.audiences || []} emptyText="All users" /></TableCell>
          {
            this.props.tab !== "draft" &&
            <TableCell align="center" className="newsletter-subscribers">
              {(newsletter.status !== 'Draft' || !!newsletter.numberSubscribed) && <span title="Subscribers" className="subscriber-icon"><NotificationsActiveOutlinedIcon fontSize="small" /><span>{numeral(newsletter.numberSubscribed).format('0,0')}</span></span>}
              {!!newsletter.numberUnsubscribed && <span title="Unsubscribers" className="subscriber-icon"><NotificationsOffOutlinedIcon fontSize="small" /><span>{numeral(newsletter.numberUnsubscribed).format('0,0')}</span></span>}
            </TableCell>
          }
        </TableRow>
      </React.Fragment>
    );
  }


  private onChangeFilters = (filters: Partial<NewsletterFilterValues>) => {
    const updatedFilters = { ...this.state.filters, ...filters };
    this.setState({ filters: updatedFilters, hasFiltersApplied: true, isPagedLoad: true });
    this.props.fetchPage(1, updatedFilters);
  }

  private onClearFilters = () => {
    this.setState({ hasFiltersApplied: false, filters: { ...this.getDefaultFilterValues() }, isPagedLoad: true });
    this.props.fetchPage(1, {});
  }


  private onSelectNewsletter = (newsletter: NewsletterDigest) => {
    if (newsletter.status === "Draft")
      this.props.redirectTo("/" + this.props.tenant + "/admin/newsletters/" + newsletter.id);
    else
      this.props.redirectTo("/" + this.props.tenant + "/admin/newsletters/" + newsletter.id + "/dashboard");
  }

  private onChangePage = (page: number, pageAmount: number) => {
    this.props.fetchPage(page + 1, this.state.filters, pageAmount);
  }

  //Default to descending, if currently descending make it ascending.
  private onClickHeader = (descValue: SortStyle | undefined, ascValue: SortStyle | undefined) => {
    const rowsPerPage = Cookies.get(ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT);
    
    if(descValue === undefined || ascValue === undefined) {
      return;
    }

    if(this.state.sortMethod === descValue) {
      this.setState({sortMethod: ascValue, filters: {...this.state.filters, sortType: ascValue}}, () => {
        this.onChangePage(0, Number(rowsPerPage) ?? 10);
      });
    }
    else {
      this.setState({sortMethod: descValue, filters: {...this.state.filters, sortType: descValue}}, () => {
        this.onChangePage(0, Number(rowsPerPage) ?? 10);
      });
    }
  }

  private onSelectAllNewsletters = () => {
    if (this.state.selectedItems.length === (this.props.page?.results.length ?? 100))
      this.setState({ selectedItems: [] });
    else
      this.setState({ selectedItems: this.props.page?.results ?? [] });
  }

  private isNewsletterSelected = (newsletter: NewsletterDigest): boolean => {
    return this.state.selectedItems.findIndex((selectedItem) => selectedItem.id === newsletter.id) !== -1;
  }

  private onCheckNewsletter = (ev, newsletter: NewsletterDigest) => {
    ev.stopPropagation();
    let selectedItems = this.state.selectedItems.slice();

    if (selectedItems.findIndex((selectedItem) => selectedItem.id === newsletter.id) === -1)
      selectedItems = selectedItems.concat([newsletter]);
    else
      selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== newsletter.id);

    this.setState({ selectedItems });
  }

  private getToolbar = (): JSX.Element => {
    const { selectedItems } = this.state;
    let allEnabled = selectedItems.every(s => s.status === "Enabled");
    let allDisabled = selectedItems.every(s => s.status === "Disabled");

    if (!selectedItems.length)
      return <React.Fragment></React.Fragment>;
    
    return (
      <TabContentToolbar>
        <div>
          {this.getToolbarIcon(`${selectedItems.length} selected`, <ClearIcon />, this.onClearAllNewsletters)}
        </div>
        <div>
          {allEnabled &&
            <React.Fragment>
              {this.getToolbarIcon("Disable", <BlockIcon style={{ transform: "rotate(90deg)" }}/>, this.disableNewsletters)}
            </React.Fragment>
          }
          {allDisabled &&
            <React.Fragment>
              {this.getToolbarIcon("Enable", <CheckCircleOutlineIcon />, this.enableNewsletters)}
            </React.Fragment>
          }
          {this.getToolbarIcon("Delete", <DeleteIcon />, this.deleteSelectedNewsletters)}
        </div>
      </TabContentToolbar>
    );
  }

  private getToolbarIcon = (title: string, icon: React.ReactNode, onClick: () => void) => {
    return (
      <Button aria-label={title.toLowerCase()} color="primary" startIcon={icon} onClick={onClick}>
        {title}
      </Button>
    );
  }

  private onClearAllNewsletters = () => {
    this.setState({ selectedItems: [] });
  }

  private deleteSelectedNewsletters = async () => {
    let idsToDelete = this.state.selectedItems.map(i => i.id);

    if (await confirm.show({
      title: `Delete Newsletters`,
      text: (
        <div>
          <div>You're about to permanently delete these newsletters. You'll lose all queued and sent issues, reports, and settings associated with these. You cannot undo this action.</div>
          <br />
          <div>Are you sure?</div>
        </div>
      ),
      yesColor: "#a80000",
      yesText: "Delete",
      noText: "Cancel"
    })){
      if(idsToDelete.length > 0) {
        let result = await this.props.deleteNewsletters(idsToDelete);
        
        if(result) {
          this.props.onNewsletterChanged("Newsletters deleted");
            this.onClearAllNewsletters();
        }
      }
    }
  }

  private disableNewsletters = async () => {
    let idsToDelete = this.state.selectedItems.map(i => i.id);

    if(await confirm.show({
      title: "Disable Newsletters",
      text: "You're about to disable these newsletters.<br><br>Are you sure?",
      yesText: "Disable",
      noText: "Cancel"
    })) {
      let result = await this.props.disableNewsletters(idsToDelete)
       
      if(result) {
        this.props.onNewsletterChanged("Newsletters disabled")
        this.onClearAllNewsletters();
      }
    }
  }

  private enableNewsletters = async () => {
    let idsToDelete = this.state.selectedItems.map(i => i.id);

    if(await confirm.show({
      title: "Enable Newsletters",
      text: "You're about to enable these newsletters.<br><br>Are you sure?",
      yesText: "Enable",
      noText: "Cancel"
    })) {
      let result = await this.props.enableNewsletters(idsToDelete);
      
      if(result) {
        this.props.onNewsletterChanged("Newsletters enabled")
        this.onClearAllNewsletters();
      }
    }
  }

  private resetSelection = () => {
    this.setState({selectedItems: []});
  }
}

interface ComponentState {
  filters: Partial<NewsletterFilterValues>;
  hasFiltersApplied: boolean;
  isPagedLoad: boolean;
  copyNewsletter?: NewsletterDigest;
  selectedItems: NewsletterDigest[];
  sortMethod: SortStyle;
}

interface ComponentProps {
  show: boolean;
  tab: NewsletterListComponentTab;
  isFetching: boolean;
  page?: NewsletterListingPage;
  audiences: Audience[];
  onCreateCopy: (newsletter: NewsletterDigest) => any;
  fetchPage: (pageNumber: number, filters: Partial<NewsletterFilterValues>, pageAmount?: number) => void;
  onNewsletterChanged: (successMessage:string) => any;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => ({
    ...ownProps,
    tenant: state.tenant.id,
    tenantSettings: state.settings.tenantSettings,
  }),
  {
    deleteNewsletter: actions.deleteNewsletter,
    deleteNewsletters: actions.deleteNewsletters,
    disableNewsletter: actions.disableNewsletter,
    disableNewsletters: actions.disableNewsletters,
    enableNewsletter: actions.enableNewsletter,
    enableNewsletters: actions.enableNewsletters,
    redirectTo: push
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(NewsletterList);
import moment from "moment";

import { ValidationChecks } from "modules/common/components/authoring/models";
import { Post } from "../../models";
import isValidEmail from "utils/isValidEmail";

export const isValid = (validationChecks: ValidationChecks): boolean => {
  const invalidChecks = validationChecks.checks.filter((check) => {
    if (check.skip)
      return false;
    return !check.isValid;
  });
  return !invalidChecks.length;
};

export const getValidationChecks = (post: Partial<Post>): ValidationChecks => {
  const validation: ValidationChecks = {
    checks: [
      { key: "image", description: "Set a hero banner image", isValid: isImageValid(post), link: "#image" },
      { key: "title", description: "Create a title for each selected language", isValid: isTitleValid(post), link: "#title" },
      { key: "body", description: "Create body content for each selected language", isValid: isBodyValid(post), link: "#body" },
      { key: "tags", description: "Select topics to categorize news", isValid: isTagsValid(post), link: "#tags" },
      { key: "email", description: "Enter a name and valid email address in Published by", isValid: isPostEmailValid(post) && isAuthorValid(post), link: "#email", skip: isAuthorValid(post) && isPostEmailValid(post) },
      { key: "breakingTime", description: "Set the breaking news duration", isValid: isBreakingTimeValid(post), link: "#breaking-time", skip: !post.breakingTime },
      { key: "featuredTime", description: "Set the featured news duration", isValid: isFeaturedTimeValid(post), link: "#featured-time", skip: !post.featuredTime },
      { key: "expiryTime", description: "The expiry date must be set after the publish date", isValid: isExpiryTimeValid(post), link: "#expiry-date", skip: !post.expiryTime },
    ]
  };

  return validation;
}

const isAuthorValid = (post: Partial<Post>): boolean => {
  return !!post.author;
}

export const isPostEmailValid = (post: Partial<Post>): boolean => {
  return isValidEmail(post.authorEmail || "");
}

const isBodyValid = (post: Partial<Post>): boolean => {
  if (!post.translatedContent) {
    return false;
  }

  const invalid = !!Object.keys(post.translatedContent).filter((lcid) => {
    const body = post.translatedContent![lcid].body;
    return !body || !body.trim().length;
  }).length;
  return !invalid;
}

const isBreakingTimeValid = (post: Partial<Post>): boolean => {
  const { breakingTime, expiryTime, publishTime } = post;

  if (!breakingTime)
    return true;

  if (!publishTime) {
    if (moment(breakingTime) < moment())
      return false;
  }

  if (!!publishTime) {
    if (moment(breakingTime) < moment(publishTime))
      return false;
  }

  if (!!expiryTime && moment(breakingTime) > moment(expiryTime))
    return false;

  return true;
}

const isExpiryTimeValid = (post: Partial<Post>): boolean => {
  const { expiryTime, publishTime } = post;

  if (!expiryTime)
    return true;

  if (!publishTime && moment(expiryTime) < moment())
    return false;
  if (!!publishTime && moment(expiryTime) < moment(publishTime))
    return false;

  return true;
}

const isFeaturedTimeValid = (post: Partial<Post>): boolean => {
  const { expiryTime, featuredTime, publishTime } = post;

  if (!featuredTime)
    return true;

  if (!publishTime) {
    if (moment(featuredTime) < moment())
      return false;
    if (moment(featuredTime) > moment().add(14, "days"))
      return false;
  }

  if (!!publishTime) {
    if (moment(featuredTime) < moment(publishTime))
      return false;
    if (moment(publishTime) < moment() && moment(featuredTime) > moment().add(14, "days"))
      return false;
    if (moment(publishTime) >= moment() && moment(featuredTime) > moment(publishTime).add(14, "days"))
      return false;
  }

  if (!!expiryTime && moment(featuredTime) > moment(expiryTime))
    return false;

  return true;
}

const isImageValid = (post: Partial<Post>): boolean => {
  return !!post.image && !!post.image.id;
}

const isTagsValid = (post: Partial<Post>): boolean => {
  return !!post.tags && !!post.tags.length;
}

const isTitleValid = (post: Partial<Post>): boolean => {
  if (!post.translatedContent) {
    return false;
  }

  const invalid = !!Object.keys(post.translatedContent).filter((lcid) => {
    const title = post.translatedContent![lcid].title;
    return !title || !title.trim().length;
  }).length;
  return !invalid;
}

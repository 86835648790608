import EditorWrapperLayout from "modules/common/components/authoring/editorLayouts/editorWrapperLayout";
import MainEditorLayout from "modules/common/components/authoring/editorLayouts/mainEditorLayout";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import BasePage from "pages/common/basePage";
import React, { useEffect, useLayoutEffect, useState } from "react";
import EmailEditor from "./emailEditor";
import EmailSettingsPanel from "./emailSettingsPanel";
import AuthoringAppBar from "modules/common/components/authoring/authoringAppBar";
import { useMediaQuery, useTheme } from "@mui/material";
import { BackItem } from "pages/common/breadcrumb";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { RouteComponentProps, withRouter } from "react-router";
import { push } from "react-router-redux";
import EmailAuthoringActions from "./emailAuthoringEditorActions";
import { setMainStyle, setShouldDisplayNav } from "modules/adminLayout/actionCreator";
import Preview, { PreviewType } from "modules/common/components/authoring/dialogs/preview";
import EmailContentView from "../emailContentView";
import ContentGuidelinePreview from "modules/settings/components/content/guidelines/contentGuidelinePreview";
import { HintType } from "modules/common/components/authoring/dialogs/guidelineHint";
import { createNewEmail, DEFAULT_EMAIL, emailsSlice, getEmailToEdit, saveExistingEmail } from "modules/emails/reducer";
import { IEmail } from "modules/emails/models";
import { actions as NewsletterActions, Address } from "modules/newsletters";
import { actions as AudienceActions } from "modules/audiences";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import SuccessSnackbar from "modules/common/components/snackbars/successSnackbar";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import EmailOneLastCheck from "../emailOneLastCheck";
import { emailsApi } from "api/instances";

import "../../styles/emailEditorPage.sass";
import "../../../common/components/authoring/styles/authoringLayout.sass";
import { PickerLocalization } from "modules/common/components/pickerLocalization";

interface IEmailEditorPageProps {}

const EmailEditorPage: React.FC<PropsWithRedux> = ({
    redirectTo,
    match,
    changedSinceSaved,
    shouldDisplayNav,
    setMainStyle,
    setShouldDisplayNav,
    isFetching,
    getEmailSenderConfig,
    email,
    getAudiences,
    isSaving,
    changedSinceLastSaved,
    shouldOpenOneLastCheck
}) => {
    const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(true);
    const [showUnsavedWarning, setShowUnsavedWarning] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [openContentGuidelines, setOpenContentGuidelines] = useState(false);
    const [fromEmailAddresses, setFromEmailAddresses] = useState<Address[]>([]);
    const [configFetched, setConfigFetched] = useState(false);
    const [showDiscardDialog, setShowDiscardDialog] = useState(false);
    const [showOneLastCheck, setShowOneLastCheck] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const theme = useTheme();
    const isSmallAndSmaller = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const dispatch = useDispatch();
    const activeLcid = "en-us"; // currently only support en-us lcid

    const { successMessage, errorMessage, redirectUrl } = useSelector((state: GlobalApplicationState) => state.emails);
    const setSuccessMessage = (message: string) => dispatch({ type: emailsSlice.actions.SET_EMAIL_SUCCESS_MESSAGE, payload: { message }});
    const setErrorMessage = (message: string) => dispatch({ type: emailsSlice.actions.SET_EMAIL_ERROR_MESSAGE, payload: { message }});
    const setShouldOpenOneLastCheck = (shouldOpen: boolean) => 
        dispatch({ type: emailsSlice.actions.SET_SHOULD_OPEN_ONE_LAST_CHECK, payload: { shouldOpen }});
    const showEmailSentConfirmation = (sentTime: string, emailId: string) => dispatch({ 
        type: emailsSlice.actions.SET_SHOW_SENT_CONFIRMATION, 
        payload: { 
            sentConfirmation: {
                shouldShowDialog: true,
                expectedSentTime: sentTime,
                emailId
            }
        }
    });
    
    const tenantId = match.params.tenant;
    const emailId = match.params.emailId;
    const isEmailIdPresent = React.useMemo(() => !!emailId && emailId !== "new", [emailId]);
    const isLoading = isFetching || !configFetched || isSaving;
    const isEmailValid = email.subject.length > 0 && 
        email.subject.some(s => !!s.text) && 
        (email.sendToAllUsers || email.audiences.length > 0) && 
        !!email.fromSender.email && 
        !!email.fromSender.name;

    useLayoutEffect(() => {
        if (shouldDisplayNav) {
            setShouldDisplayNav(false);
            setMainStyle({ backgroundColor: "#FFFFFF" });
        }
    }, [shouldDisplayNav, setMainStyle, setShouldDisplayNav]);

    useEffect(() => {
        const getAndSetInitialConfig = async () => {
            const config = await getEmailSenderConfig();
            setFromEmailAddresses(config.addresses.sort((a, b) => a.fromEmailName.toLowerCase().localeCompare(b.fromEmailName.toLowerCase())));
            setConfigFetched(true);
        }

        if (!configFetched)
            getAndSetInitialConfig();
        
        getAudiences();
    }, []);

    useEffect(() => {
        if (isEmailIdPresent) {
            dispatch(getEmailToEdit({ emailId }));
        }
    }, [isEmailIdPresent]);

    useEffect(() => {
        if (email.id && !changedSinceLastSaved) {
            if (shouldOpenOneLastCheck) {
                setShouldOpenOneLastCheck(false);
                setShowOneLastCheck(true);
            }
        }
    }, [email.id, changedSinceLastSaved, shouldOpenOneLastCheck]);

    const updateEmail = (updatedEmailFields: Partial<IEmail>, changedSinceLastSaved: boolean = true) => dispatch({ 
        type: emailsSlice.actions.UPDATE_EMAIL_EDITOR, 
        payload: { 
            email: {
                ...updatedEmailFields
            } as Partial<IEmail>,
            changedSinceLastSaved
        }
    });

    useEffect(() => {
        if (fromEmailAddresses.length > 0 && !email.fromSender.email && !email.fromSender.name)
            updateEmail(
                { 
                    fromSender: { 
                        email: fromEmailAddresses[0].fromEmailAddress, 
                        name: fromEmailAddresses[0].fromEmailName 
                    }
                }, 
                false
            );
    }, [fromEmailAddresses]);

    const manageEmailsUrl = `/${tenantId}/admin/emails`;

    useEffect(() => {   
        if (redirectUrl) {
            if (redirectUrl === manageEmailsUrl)
                redirectToManageEmails();
            else 
                redirectTo(redirectUrl);

            dispatch({ type: emailsSlice.actions.SET_REDIRECT_URL, payload: { redirectUrl: undefined }});
        }
    }, [redirectUrl]);

    const onToggleDrawer = () => setSettingsDrawerOpen(prev => !prev);

    const redirectToManageEmails = React.useCallback(() => {
        dispatch({
            type: emailsSlice.actions.SET_EMAIL_EDITOR, 
            payload: { 
                email: DEFAULT_EMAIL
            }
        });
        dispatch({type: emailsSlice.actions.SET_TEST_EMAILS, payload: { testEmails: [] }});
        redirectTo(`/${tenantId}/admin/emails`);
    }, [tenantId, redirectTo]);

    const backToManagePosts = React.useCallback(() => {
        changedSinceSaved
            ? setShowUnsavedWarning(true)
            : redirectToManageEmails();
    }, [changedSinceSaved, redirectToManageEmails]);

    const onSendEmail = async () => {
        setIsSendingEmail(true);

        try {
            await emailsApi.sendEmail(email.id);
            showEmailSentConfirmation(email.sentTime && new Date(email.sentTime) >= new Date() ? email.sentTime : new Date().toISOString(), email.id);
            redirectToManageEmails();
        }
        catch {
            setErrorMessage("There was an error. Please try again.");
            setIsSendingEmail(false);
            setShowOneLastCheck(false);
        }
    }

    const onClickSend = async () => {
        if (!email.id || changedSinceLastSaved) {
            saveEmail();
            setShouldOpenOneLastCheck(true);
        }
        else
            setShowOneLastCheck(true);
    }

    const getActions = React.useMemo((): JSX.Element =>
        <EmailAuthoringActions
            onSend={onClickSend}
            isSendDisabled={!isEmailValid}
            onDiscard={() => changedSinceSaved && setShowDiscardDialog(true)}
            isDiscardDisabled={!changedSinceSaved}
            onPreview={() => setShowPreview(true)}
            onSave={() => saveEmail()}
            isSaveDisabled={!changedSinceSaved}
            onBack={backToManagePosts}
            onToggleSettingsPanel={onToggleDrawer}
            isToggleSettingsPanelDisabled={!settingsDrawerOpen}
            openGuidelines={() => setOpenContentGuidelines(true)}
        />, 
        [settingsDrawerOpen, changedSinceSaved, isEmailValid, openContentGuidelines]
    );

    const getMainEditorClassName = (): string => {
        let result: string = "";

        if (isSmallAndSmaller) {
            result = "main-editor-paper";
        } else {
            result = settingsDrawerOpen ? "main-editor-paper main-editor-paper-open" : "main-editor-paper main-editor-paper-closed";
        }

        return result;
    };

    const contentGuidelinePreview = React.useMemo(() => {
        return <ContentGuidelinePreview
            showPreview={openContentGuidelines}
            onClose={() => setOpenContentGuidelines(false)}
            isAuthoring={true}
            hintType={HintType.Post}
        />;
    }, [openContentGuidelines]);

    const saveEmail = (redirectUrl?: string) => {
        if (!isLoading) {
            if (!isEmailIdPresent) {
                dispatch(createNewEmail({ redirectUrl }));
            }
            else {
                dispatch(saveExistingEmail({ redirectUrl }));
            }
        }
    };

    useEffect(() => {
        if (email.id !== "" && !isEmailIdPresent)
            redirectTo(`/${match.params.tenant}/admin/emails/edit/${email.id}`);
    }, [email.id, isEmailIdPresent]);

    return <BasePage fullWidth>
        <AuthoringAppBar
            backAction={
                isSmallAndSmaller
                    ? <></>
                    : <BackItem 
                        textStyle={{ fontWeight: 500 }} 
                        title="Back to Manage Emails"
                        onClick={backToManagePosts}
                    />
            }
            actions={getActions}
        />
        <div id="email-editor-content" className="authoring-page">
            <LoadingOverlay show={isLoading} styles={{ zIndex: 1201 }} />
            {
                !isLoading && <>
                    <EditorWrapperLayout>
                        <MainEditorLayout
                            paperProps={{
                                className: getMainEditorClassName(),
                                elevation: 0,
                            }}
                        >   
                            <EmailEditor updateEmail={updateEmail} activeLcid={activeLcid}/>
                        </MainEditorLayout>
                    </EditorWrapperLayout>
                    <PickerLocalization>
                        <EmailSettingsPanel
                            open={settingsDrawerOpen}
                            toggleOpen={onToggleDrawer}
                            fromEmailAddresses={fromEmailAddresses}
                            updateEmail={updateEmail}
                            onSuccessMessage={setSuccessMessage}
                            onErrorMessage={setErrorMessage}
                            onSaveEmail={saveEmail}
                        />
                    </PickerLocalization>
                </>
            }
        </div>
        <Preview
            onClose={() => setShowPreview(false)}
            open={showPreview}
            previews={[{ type: PreviewType.Plain, component: <EmailContentView email={email} activeLcid={activeLcid}/>, label: "DESKTOP" }]}
        />
        <ConfirmDialog
            title="Back to Manage Emails"
            confirmLabel="KEEP EDITING"
            denyLabel="EXIT"
            open={showUnsavedWarning}
            onClose={() => setShowUnsavedWarning(false)}
            onConfirm={() => setShowUnsavedWarning(false)}
            onDeny={redirectToManageEmails}
        >
            <div style={{ minWidth: 400 }}>
                <div>
                    You have unsaved changes to your email. Changes will not be saved if you exit.</div>
                <br />
                <div>Do you want to continue?</div>
            </div>
        </ConfirmDialog>
        <ConfirmDialog
            title="Discard Changes"
            confirmLabel="SAVE CHANGES"
            denyLabel="DISCARD"
            open={showDiscardDialog}
            onClose={() => setShowDiscardDialog(false)}
            onConfirm={async () => {
                setShowDiscardDialog(false);
                saveEmail(`/${tenantId}/admin/emails`);
            }}
            onDeny={redirectToManageEmails}
        >
            <div style={{ minWidth: 400 }}>
                <div>
                    You have unsaved changes to your email. Changes will not be saved if you exit.</div>
                <br />
                <div>Do you want to continue?</div>
            </div>
        </ConfirmDialog>
        {contentGuidelinePreview}
        <EmailOneLastCheck
            show={showOneLastCheck}
            onClose={() => setShowOneLastCheck(false)}
            sending={isSendingEmail}
            sendEmail={onSendEmail}
            onSaveEmail={saveEmail}
            onSuccessMessage={setSuccessMessage}
            onErrorMessage={setErrorMessage}
            activeLcid={activeLcid}
        />
        <SuccessSnackbar successMessage={successMessage} clearSuccessMessage={() => setSuccessMessage("")}/>
        <ErrorSnackbar errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage("")}/>
    </BasePage>;
};

interface RouteParams {
    tenant: string;
    emailId: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: IEmailEditorPageProps & RouteComponentProps<RouteParams>) => ({
        ...ownProps,
        changedSinceSaved: state.emails.editor.changedSinceLastSaved,
        shouldDisplayNav: state.adminLayout.shouldDisplayNav,
        email: state.emails.editor.email,
        isFetching: state.emails.editor.isFetching,
        isSaving: state.emails.editor.isSaving,
        changedSinceLastSaved: state.emails.editor.changedSinceLastSaved,
        shouldOpenOneLastCheck: state.emails.editor.shouldOpenOneLastCheck
    }),
    {
        redirectTo: push,
        setMainStyle: setMainStyle,
        setShouldDisplayNav: setShouldDisplayNav,
        getEmailSenderConfig: NewsletterActions.getConfig,
        getAudiences: AudienceActions.getAudiences,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(EmailEditorPage));
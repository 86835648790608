import { ReportTypes } from 'modules/reports/models';
import { ApiRequest } from './network';
import { SparrowApi } from 'api/network/adapters/SparrowApi';
import { DownloadAllReport } from 'modules/insights/models';
import moment from 'moment';
import { getCurrentTimeInUserTimezone } from 'utils/getCurrentTimeInUserTimezone';

export class ReportsApi {
    constructor(private _sparrowApi: SparrowApi) {
    }

    public GetReportInfo = (reportId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get(`/{tenant}/adminapi/v1/reports/reportInfo/${reportId}`)
        );
    }

    public GetReportForDownload = (reportId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .get(
                    `/{tenant}/adminapi/v1/reports/download/readReport/${reportId}`,
                    { responseType: 'arraybuffer'}
                )
        );
    }

    public SetReportDownloaded = (reportId: string) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v1/reports/markReportAsDownloaded/${reportId}`)
        );
    }

    public SendReportRequest = (report: Object) => {
        return new ApiRequest(
            this._sparrowApi.TenantRequest()
                .post(`/{tenant}/adminapi/v1/reports/addReportToQueue`, report)
        );
    }

    public SendUserReadReportRequest = (type: ReportTypes, contentId: string) => {
        let data = {
            type: type,
            contentId: contentId,
            dateRequested: moment().format("YYYY-MM-DD hh:mm A")
        };

        return this.SendReportRequest(data);
    }

    public SendAnalyticsReportRequest = (reportPayload: DownloadAllReport, reportType : string) => {
        let data = {
            type: ReportTypes.AnalyticsCsvReport,
            insights: reportPayload,
            category: reportType
        };

        return this.SendReportRequest(data);
    }

    public SendAllAnalyticsReportRequest = (reportPayload: DownloadAllReport) => {
        let data = {
            type: ReportTypes.PlatformAnalyticsReport,
            insights: reportPayload
        };

        return this.SendReportRequest(data);
    }

    public SendSMSReportRequest = (type: ReportTypes) => {
        const tz = getCurrentTimeInUserTimezone();

        let data = {
            type: type,
            clientTimeZone: tz.format('z'),
            clientTimeOffset: tz.format('Z')
        };

        return this.SendReportRequest(data);
    }

    public SendEmailActivityReportRequest = (emailId?: string) => {
        const tz = getCurrentTimeInUserTimezone();

        let data = {
            type: ReportTypes.EmailActivityReport,
            clientTimeZone: tz.format('z'),
            clientTimeOffset: tz.format('Z'),
            dateRequested: moment().format("YYYY-MM-DD hh:mm A"),
            contentId: emailId
        };

        return this.SendReportRequest(data);
    }
}
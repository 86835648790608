import { ActivityApi } from "./activity";
import { CommentApi } from "./comments";
import { InsightsApi } from "./insights";
import { MediaApi } from "./media";
import { PostsApi } from "./posts";
import { LinkApi } from "./links";
import { SettingsApi } from "./settings";
import { UsersApi } from "./users";
import { AudiencesApi } from "./audiences";
import { AuthInterceptor } from "./network/interceptors/AuthInterceptor";
import { GraphAuthInterceptor } from "./network/interceptors/GraphAuthInterceptor";
import { RequestFormatInterceptor } from "./network/interceptors/RequestFormatInterceptor";
import { RequestPlatformInterceptor } from "./network/interceptors/RequestPlatformInterceptor";
import { TenantAutoFillInterceptor } from "./network/interceptors/TenantAutoFillInterceptor";
import { SparrowApi } from "./network/adapters/SparrowApi";
import { MultitenancyApi } from "./multitenancy";
import { TagsApi } from "./tags";
import { SupportApi } from "./support";
import { DocumentsApi } from "./documents";
import { SyncFunctionsGraphApi } from "./network/adapters/SyncFunctionsGraphApi";
import { SyncApi } from "./syncFunctions";
import { MessagingApi } from "./messaging";
import { EventsApi } from "./events";
import { OktaAuthInterceptor } from "./network/interceptors/OktaAuthInterceptor";
import { SyncFunctionsOktaApi } from "./network/adapters/SyncFunctionsOktaApi";
import { ResourcesApi } from "./resources";
import { DraftPostsApi } from "./draftPosts";
import { LoggedEventsApi } from "./loggedEvents";
import { ReportsApi } from "./reports";
import { TenantManagementApi } from "./tenantManagement";
import { CognitiveServicesApi } from "./cognitiveServices";
import { AdminTinyTemplatesApi } from "./adminTinyTemplates";
import { SubmissionsApi } from "./submissions";
import { OspreyApi } from "./osprey";
import { ContentBandsApi, IContentBandsApi } from "./contentBands";
import { FilesApi } from "./files";
import { DynamicLinkApi } from "./dynamicLinks";
import { AdminFilesApi } from "./adminFiles";
import { HomeScreenConfigApi } from "./homeScreens";
import { EmailsApi } from "./emails";
import { DraftEventsApi } from "./draftEvents";

export const authInterceptor = new AuthInterceptor();
export const graphAuthInterceptor = new GraphAuthInterceptor();
export const oktaAuthInterceptor = new OktaAuthInterceptor();
export const requestFormatInterceptor = new RequestFormatInterceptor();
export const requestPlatformInterceptor = new RequestPlatformInterceptor();
export const tenantAutoFillInterceptor = new TenantAutoFillInterceptor();

export const sparrowApi = new SparrowApi(authInterceptor, requestFormatInterceptor, requestPlatformInterceptor, tenantAutoFillInterceptor);
export const syncFunctionsGraphApi = new SyncFunctionsGraphApi(graphAuthInterceptor, requestFormatInterceptor, tenantAutoFillInterceptor);
export const syncFunctionsOktaApi = new SyncFunctionsOktaApi(oktaAuthInterceptor, requestFormatInterceptor, tenantAutoFillInterceptor);

/* API */
export const activityApi = new ActivityApi(sparrowApi);
export const commentApi = new CommentApi(sparrowApi);
export const insightsApi = new InsightsApi(sparrowApi);
export const mediaApi = new MediaApi(sparrowApi);
export const postsApi = new PostsApi(sparrowApi);
export const linkApi = new LinkApi(sparrowApi);
export const eventsApi = new EventsApi(sparrowApi);
export const draftEventsApi = new DraftEventsApi(sparrowApi);
export const settingsApi = new SettingsApi(sparrowApi);
export const usersApi = new UsersApi(sparrowApi);
export const audiencesApi = new AudiencesApi(sparrowApi);
export const syncFunctionsApi = new SyncApi(syncFunctionsGraphApi, syncFunctionsOktaApi);
export const multitenancyApi = new MultitenancyApi(sparrowApi);
export const tagsApi = new TagsApi(sparrowApi);
export const supportApi = new SupportApi(sparrowApi)
export const documentsApi = new DocumentsApi(sparrowApi);
export const messagingApi = new MessagingApi(sparrowApi);
export const resourcesApi = new ResourcesApi(sparrowApi);
export const draftPostsApi = new DraftPostsApi(sparrowApi);
export const submissionsApi = new SubmissionsApi(sparrowApi);
export const loggedEventsApi = new LoggedEventsApi(sparrowApi);
export const reportsApi = new ReportsApi(sparrowApi);
export const tenantManagementApi = new TenantManagementApi(sparrowApi);
export const cognitiveServicesApi = new CognitiveServicesApi(sparrowApi);
export const adminTinyTemplatesApi = new AdminTinyTemplatesApi(sparrowApi);
export const ospreyApi = new OspreyApi(sparrowApi);
export const dynamicLinksApi = new DynamicLinkApi(sparrowApi);
export const contentBandApi: IContentBandsApi = new ContentBandsApi(sparrowApi);
export const filesApi = new FilesApi(sparrowApi);
export const adminFilesApi = new AdminFilesApi(sparrowApi);
export const homeScreenConfigApi = new HomeScreenConfigApi(sparrowApi);
export const emailsApi = new EmailsApi(sparrowApi);
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as actions from "../../actionCreator";
import { GlobalApplicationState } from "globalApplicationState";
import { Address, ConfigChannel, NewsletterConfigDigest, SaveNewsletterConfigModelStateErrors } from "../../models";
import InfoHover from "modules/common/components/hovers/infoHover";
import Loading from "modules/common/components/loading";
import { errorAlert } from "utils/notyPopups";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import ErrorSnackbar from "modules/common/components/snackbars/errorSnackbar";
import EditEmailSender from "./editEmailSender";

import "../../styles/dialogs.sass"

const LinkPreferences: { [channel in ConfigChannel]: string } = {
  AdminPortal: "Company Portal / Mobile",
  SharePoint: "SharePoint",
  Teams: "MS Teams"
};

class GlobalSettings extends React.Component<PropsWithRedux, ComponentState> {
  constructor(props: PropsWithRedux) {
    super(props);
    this.state = {
      senderName: "",
      senderEmail: "",
      originalName: "",
      originalEmail: "",
      errorMessage: ""
    };
  }

  public componentDidMount() {
    this.props.getConfig().then((currentConfig) => {
      var copy = JSON.parse(JSON.stringify(currentConfig)); //Make copy so that we aren't accessing the same object twice.
      this.setState({
        config: copy, 
        currentConfig: currentConfig, 
        originalName: currentConfig.fromEmailName, 
        originalEmail: currentConfig.fromEmailAddress
      });
    });
  }

  public render() {
    return (
      <React.Fragment>
        <Dialog open={this.props.dialogOpen} maxWidth={false} scroll="paper" onClose={this.props.onClose} classes={{ paper: "newsletter-dialog" }}>
          <DialogTitle className="newsletter-dialog-title">
            <div>Newsletter Global Settings</div>
            <div>
              <IconButton onClick={this.props.onClose} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            {this.getContent()}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  private getContent = (): JSX.Element => {
    if (!this.state.config)
      return <Loading padding={12} />;

    return (
      <div style={{paddingTop: "8px"}}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{width: "180px"}}>
            <div>
              Sender Emails 
                <span style={{position: "relative", top: "6px"}}>
                  <InfoHover>List of all 'From' names and emails of your newsletters. Edit a newsletter to modify its sender email. You cannot remove emails currently used in newsletter(s).</InfoHover>
                </span>
            </div>
          </div>
          <EditEmailSender
            config={this.state.config}
            onErrorMessage={errorMessage => this.setState({ errorMessage })}
            onSaveAddresses={this.onSave}
            onCheckAssociatedNewsletters={this.props.onCheckAssociatedNewsletters}
          />
        </div>
        <Divider light />
        {this.state.config.availableLinkPreferences.length > 1 && 
        <div className="global-settings-preferred-channel">
          <div>
            <span>Preferred channel when opening content</span>
            <InfoHover>This setting will redirect the reader to your preferred channel when opening content from the newsletter.</InfoHover>
          </div>
          <div>
            <FormControl error={_.some(this.state.modelErrors?.LinkPreference)}>
              <RadioGroup value={this.state.config.linkPreference} onChange={(event) => this.onChangePreferredChannel(event.target.value as ConfigChannel)}>
                {this.state.config.availableLinkPreferences.map((linkPreference) =>
                  <FormControlLabel key={linkPreference} value={linkPreference} control={<Radio color="primary" />} label={LinkPreferences[linkPreference]} />
                )}
              </RadioGroup>
              <FormHelperText>{_.first(this.state.modelErrors?.LinkPreference)}</FormHelperText>
            </FormControl>
          </div>
        </div>
        }
        <div style={{flex: "content", float: "right", marginTop: "10px", marginBottom: "5px"}}>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button variant="contained" color="primary" disabled={_.isEqual(this.state.config.linkPreference, this.state.currentConfig?.linkPreference)} onClick={() => this.onSave()}>Save changes</Button>
        </div>

        <ErrorSnackbar errorMessage={this.state.errorMessage} clearErrorMessage={this.clearErrorMessage}/>
      </div>
    );
  }

  private clearErrorMessage = () => {
    this.setState({errorMessage: ""});
  }

  private onChangePreferredChannel = (linkPreference: ConfigChannel) => {
    this.setState({ config: {...this.state.config!, linkPreference: linkPreference}});
  }

  private onSave = (addresses?: Address[]) => {
    this.setState({isSaving: true});
    var newConfig = this.state.config!;
    if (addresses) {
      newConfig.addresses = addresses;
    }
    else {
      newConfig.fromEmailAddress = this.state.originalEmail;
      newConfig.fromEmailName = this.state.originalName;
      this.setState({senderName: "", senderEmail: ""});
    }

    this.setState({config: newConfig});

    this.props.saveConfig(newConfig).then(result => {
      if(!!result.success){
        this.setState({config: result.success, currentConfig: result.success, isSaving: false, modelErrors: null});

        if (addresses === undefined) this.props.onClose();
      }else if(!!result.error){
        errorAlert('Unable to Save', 5000);
        this.setState({isSaving: false, modelErrors: result.error.ModelState});
      }
    });
  }
}


interface ComponentProps {
  onClose: () => any;
  dialogOpen: boolean;
  confirmOpen: boolean;
  closeConfirm: () => void;
  openConfirm: () => void;
  onCheckAssociatedNewsletters: (address: Address) => void;
}

interface ComponentState {
  config?: NewsletterConfigDigest;
  currentConfig?: NewsletterConfigDigest;
  modelErrors?: SaveNewsletterConfigModelStateErrors | null;
  isSaving?: boolean;
  senderName: string;
  senderEmail: string;
  originalName: string;
  originalEmail: string;
  errorMessage: string;
}

const connector = connect(
  (state: GlobalApplicationState, ownProps: ComponentProps) => 
  ({ ...ownProps }),
  {
    getConfig: actions.getConfig,
    saveConfig: actions.saveConfig
  }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(GlobalSettings);
import { emailsApi } from "api/instances";
import { useState } from "react";
import { FileDownloader } from "utils/fileDownloader";
import useIsMounted from "./useIsMounted";

type IUseDownloadRecipientsReturn = {
    isDownloadingRecipients: boolean;
    downloadRecipients: () => Promise<void>;
};

interface IUseDownloadRecipientsProps {
    onSuccessMessage: (message: string) => void;
    onErrorMessage: (message: string) => void;
    emailId: string;
};

export const useDownloadRecipients = ({
    onSuccessMessage,
    onErrorMessage,
    emailId
}: IUseDownloadRecipientsProps): IUseDownloadRecipientsReturn => {
    const [isDownloadingRecipients, setIsDownloadingRecipients] = useState(false);
    const isMounted = useIsMounted();

    const downloadRecipients = async () => {
        setIsDownloadingRecipients(true);
        try {
            const { data } = await emailsApi.getRecipientsReport(emailId);
            new FileDownloader({ name: `recipients_list.csv` }).downloadBlob(data);
            onSuccessMessage("Recipients list downloaded. Check your downloads folder.");
        }
        catch {
            onErrorMessage("There was an error. Please try again.");
        }
        finally {
            if (isMounted()) setIsDownloadingRecipients(false);
        }
    }

    return { isDownloadingRecipients, downloadRecipients };
}

import { ITranslatedText } from "modules/common/models";
import { EMAIL_PERSONALIZATION_TOKENS, MERGE_TAG_PREFIX, MERGE_TAG_SUFFIX, PersonalizationTokenValue } from "modules/emails/models";
import { CurrentUserSettings } from "modules/settings";

// Maps the personalization token to the actual text we want
export const personalizationTokenMapper = (tokenValue: PersonalizationTokenValue, currentUser: CurrentUserSettings) => {
    let replaceText = "";

    switch (tokenValue) {
        case PersonalizationTokenValue.UserFirstName:
            replaceText = currentUser.firstName;
            break;
        case PersonalizationTokenValue.UserLastName:
            replaceText = currentUser.lastName;
            break;
    }

    return replaceText;
}

// replaces string text occurances of the token to what the value of the token is
export const replaceTextPersonalizationTokens = (subject: ITranslatedText[], activeLcid: string, currentUser: CurrentUserSettings): string => {
    let subjectText = subject.filter(s => s.lcid === activeLcid)[0]?.text;

    if (subjectText) {
        for (const token of EMAIL_PERSONALIZATION_TOKENS) {
            const personalizationTokenText = `${MERGE_TAG_PREFIX}${token.value}${MERGE_TAG_SUFFIX}`;
            const replaceValue = personalizationTokenMapper(token.value, currentUser);
            subjectText = subjectText.replaceAll(personalizationTokenText, replaceValue);
        }
    }

    return subjectText ?? "";
}

// replaces html text occurances of the token (formatted for tinyMCE) to what the value of the token
export const replaceBodyPersonalizationTokens = (body: ITranslatedText[], activeLcid: string, currentUser: CurrentUserSettings): string => {
    let bodyHtmlText = body.filter(b => b.lcid === activeLcid)[0]?.text ?? "";

    if (bodyHtmlText) {
        for (const token of EMAIL_PERSONALIZATION_TOKENS) {
            // parse the body html as a document
            const bodyDoc = new DOMParser().parseFromString(bodyHtmlText, "text/html");
            // find all merge tag elements <span className="mce-mergetag"></span>
            const mergeTagElements = bodyDoc.querySelectorAll<HTMLSpanElement>(`span.mce-mergetag`);

            // go through each mergetag element that matches the token value and replace it with just a text span of the value of the token
            for (const matchingMergeTagElement of Array.from(mergeTagElements).filter(e => e.innerText === token.value)) {
                const parent = matchingMergeTagElement.parentElement;

                if (parent) {
                    const newSpan = document.createElement("span");
                    newSpan.textContent = personalizationTokenMapper(token.value, currentUser);

                    parent.removeChild(matchingMergeTagElement);
                    parent.appendChild(newSpan);
                }
            }

            // replace the body text with the new text content
            bodyHtmlText = bodyDoc.textContent ?? "";
        }
    }

    return bodyHtmlText ?? "";
}
import MoreOptionsButton from "modules/common/components/buttons/moreOptionsButton";
import React from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MoreOptionsItem from "modules/common/components/moreOptionsItem";

interface IMoreOptionsEmailProps {
    onDownloadEmailActivityLog: () => void;
    onDownloadUserData: () => void;
    onDownloadEmailOutbox: () => void;
    onClickManageSenderEmails: () => void;
}

const MoreOptionsEmail: React.FC<IMoreOptionsEmailProps> = ({
    onDownloadEmailActivityLog,
    onDownloadUserData,
    onDownloadEmailOutbox,
    onClickManageSenderEmails

}) => {
    return <MoreOptionsButton>
        <List disablePadding>
            <MoreOptionsItem
                text="Download email activity log"
                onClick={onDownloadEmailActivityLog}
            />
            <MoreOptionsItem
                text="Download user data"
                onClick={onDownloadUserData}
            />
            <MoreOptionsItem
                text="Download email outbox"
                onClick={onDownloadEmailOutbox}
            />
            <Divider light />
            <MoreOptionsItem
                text="Manage sender emails"
                onClick={onClickManageSenderEmails}
            />
        </List>
    </MoreOptionsButton>;
};

export default MoreOptionsEmail;

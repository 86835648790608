import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { GlobalApplicationState } from "globalApplicationState";
import FilledInput from "../forms/inputs/filledInput";
import { AuthoringTinyEditor } from "./authoringTinyMce";
import * as actions from 'modules/posts/actionCreator';
import * as galleryActions from "modules/gallery/actionCreator";

import ImageGalleryDialog from "./dialogs/imageGalleryDialog";
import IntenseEmphasis from "./dialogs/intenseEmphasis";
import { useTinyMceAuthoringLogic } from "modules/common/hooks/useTinyMceAuthoringLogic";
import { ICustomCssModelv1 } from "api/files";
import { PromptTypeEnum } from "modules/smartContent/models";
import { RawEditorOptions } from "tinymce";
import { IPersonalizationToken } from "modules/emails/models";

interface IBodyv2Props {
    value?: string;
    label?: string;
    editorOptions?: (RawEditorOptions & { selector?: undefined; target?: undefined; });
    activeLcid?: string;
    enableSmartContentBodyButton?: boolean;
    enableSmartContentTitleButton?: boolean;
    enableSmartContentSummarizeButton?: boolean;
    fetchingAnalyses?: boolean;
    showSCDropdownButton?: boolean;
    customCss?: ICustomCssModelv1;
    smartContentSuggestion?: string;
    onFocus?: () => void;
    onFullscreen?: () => void;
    onChange: (body: string) => void;
    required?: boolean;
    onShowSmartContentInsert?: (highlightedText: string, promptType: PromptTypeEnum) => void;
    clearSmartContentSuggestion?: () => void;
    generateAndOpenSmartContent?: (promptTypeForGeneration: PromptTypeEnum, promptTypeForInsert: PromptTypeEnum, body?: string) => void;
    personalizationTokens?: IPersonalizationToken[];
}

const Bodyv2: React.FunctionComponent<PropsWithRedux> = ({
    value,
    label = "Body",
    editorOptions,
    defaultLcid,
    activeLcid,
    fetchingAnalyses = false,
    customCss,
    smartContentSuggestion,
    showSCDropdownButton,
    onFocus,
    onFullscreen,
    onChange,
    grabMentionUsers,
    getImage,
    required = true,
    onShowSmartContentInsert,
    clearSmartContentSuggestion,
    generateAndOpenSmartContent,
    personalizationTokens
}) => {
    const {
        editorRef,
        intenseEmphasisDialogOpen,
        inlineImageDialogOpen,
        onShowInlineImageDialog,
        onShowIntenseEmphasisDialog,
        onApplyInlineImage,
        onApplyIntenseEmphasis,
        onHideInlineImageDialog,
        onHideIntenseEmphasisDialog,
    } = useTinyMceAuthoringLogic(getImage);

    return (
        <>
            <FilledInput
                label={label}
                required={required}
                labelOnly
                value={""}
            />
            <AuthoringTinyEditor
                loading={fetchingAnalyses}
                activeLcid={activeLcid}
                customCss={customCss}
                value={value}
                onChange={onChange}
                defaultLcid={defaultLcid}
                editorRef={editorRef}
                showSCDropdownButton={showSCDropdownButton}
                onShowInlineImageDialog={onShowInlineImageDialog}
                onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                grabMentionUsers={grabMentionUsers}
                onFocus={onFocus}
                onShowSmartContentInsert={onShowSmartContentInsert}
                initOptions={editorOptions}
                onFullscreen={onFullscreen}
                smartContentSuggestion={smartContentSuggestion}
                clearSmartContentSuggestion={clearSmartContentSuggestion}
                generateAndOpenSmartContent={generateAndOpenSmartContent}
                personalizationTokens={personalizationTokens}
            />
            <ImageGalleryDialog show={inlineImageDialogOpen} allowLink onApply={onApplyInlineImage} onClose={onHideInlineImageDialog} />
            <IntenseEmphasis show={intenseEmphasisDialogOpen} onApply={onApplyIntenseEmphasis} onClose={onHideIntenseEmphasisDialog} />
        </>
    );
}
const connector = connect(
    (state: GlobalApplicationState, ownProps: IBodyv2Props) => ({
        ...ownProps,
        defaultLcid: state.settings.tenantSettings.defaultLCID,
        activeLanguage: state.settings.activeLanguageLCID,
    }),
    {
        grabMentionUsers: actions.getMentionsUsers,
        getImage: galleryActions.getImage
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(Bodyv2);

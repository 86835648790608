import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Divider, FormControl, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import EditAudiences from "modules/newsletters/common/editAudiences";
import Chips from "modules/common/components/chips/chips";
import DateAndTime from "modules/common/components/forms/inputs/dateAndTime";
import { Address } from "modules/newsletters";
import { GlobalApplicationState } from "globalApplicationState";
import { IEmail } from "modules/emails/models";
import ChipList from "modules/common/components/chipList";
import { emailsApi } from "api/instances";
import isValidEmail from "utils/isValidEmail";
import LoadingCircle from "modules/common/components/loadingCircle";
import { emailsSlice } from "modules/emails/reducer";
import RecipientCountDownload from "./recipientCountDownload";
import { useDownloadRecipients } from "modules/common/hooks/useDownloadRecipients";
import DrawerWithBookmarkButton from "modules/common/components/drawers/drawerWithBookmarkButton";

import "../../styles/emailSettingsPanel.sass";

interface IEmailSettingsPanelProps {
    open: boolean;
    toggleOpen: () => void;
    fromEmailAddresses: Address[];
    updateEmail: (updatedEmailFields: Partial<IEmail>, changedSinceLastSaved?: boolean) => void;
    onSuccessMessage: (message: string) => void;
    onErrorMessage: (message: string) => void;
    onSaveEmail: () => void;
}

const EmailSettingsPanel: React.FC<IEmailSettingsPanelProps> = ({
    open,
    toggleOpen,
    fromEmailAddresses,
    updateEmail,
    onSuccessMessage,
    onErrorMessage,
    onSaveEmail
}) => {
    const { 
        email, 
        shouldDownloadRecipientsList, 
        testEmails, 
        shouldSendTestEmails, 
        changedSinceLastSaved 
    } = useSelector((state: GlobalApplicationState) => state.emails.editor);
    const audiences = useSelector((state: GlobalApplicationState) => state.audiences.audiences);
    const [emailAddressValueForTest, setEmailAddressValueForTest] = useState("");
    const [isReplyEmailFieldFocussed, setIsReplyEmailFieldFocussed] = useState(false);
    const [replyEmailInnerValue, setReplyEmailInnerValue] = useState(email.replyAddress);
    const isReplyEmailFieldInvalid = !!replyEmailInnerValue && !isValidEmail(replyEmailInnerValue);
    const [showInvalidForEmailTest, setShowInvalidForEmailTest] = useState(false);
    const { downloadRecipients } = useDownloadRecipients({onSuccessMessage, onErrorMessage, emailId: email.id});
    const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
    const dispatch = useDispatch();

    const setEmailAddressesForTest = (testEmails: string[]) => 
        dispatch({type: emailsSlice.actions.SET_TEST_EMAILS, payload: { testEmails }});

    const setShouldDownloadRecipientsList = (shouldDownload: boolean) => 
        dispatch({ type: emailsSlice.actions.SET_SHOULD_DOWNLOAD_RECIPIENTS_LIST, payload: { shouldDownload }});

    const setShouldSendTestEmails = (shouldSend: boolean) => 
        dispatch({ type: emailsSlice.actions.SET_SHOULD_SEND_TEST_EMAILS, payload: { shouldSend }});

    const truncateText = (text: string, maxLength: number): string => 
        text.length > maxLength 
            ? `${text.substring(0, maxLength - 3)}...`
            : text;

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const cleanedEmailAddressValueForTest = emailAddressValueForTest.trim();

        if (cleanedEmailAddressValueForTest === "") return;

        if (event.key === "Enter" || event.key === ",") {
            if (isValidEmail(cleanedEmailAddressValueForTest)) {
                setEmailAddressesForTest([...testEmails, cleanedEmailAddressValueForTest]);
                setEmailAddressValueForTest("");
            }
            else {
                setShowInvalidForEmailTest(true);
            }
        }
    };

    useEffect(() => {
        if (email.id && !changedSinceLastSaved) {
            if (shouldDownloadRecipientsList) {
                setShouldDownloadRecipientsList(false);
                downloadRecipients();
            }
            else if (shouldSendTestEmails) {
                setShouldSendTestEmails(false);
                sendTestEmail();
            }
        }
    }, [email.id, shouldDownloadRecipientsList, shouldSendTestEmails, changedSinceLastSaved]);

    const sendTestEmail = async () => {
        setIsSendingTestEmail(true);

        try {
            await emailsApi.testEmail(email.id, testEmails);
            onSuccessMessage("Successfully sent your test email.");
        }
        catch {
            onErrorMessage("There was an error. Please try again.");
        }
        finally {
            setIsSendingTestEmail(false);
        }
    }

    const onClickSendTestEmails = async () => {
        // same thing here as for downloading the reports for emails that 
        // don't have an id (are new) or have changes not yet saved.
        if (!email.id || changedSinceLastSaved) {
            onSaveEmail();
            setShouldSendTestEmails(true);
        }
        else
            await sendTestEmail();
    }
        
    return <DrawerWithBookmarkButton
        open={open}
        onBookmarkClick={toggleOpen}
        PaperProps={{ id: "email-settings-drawer" }}
    >
        <div className="email-editor-settings-container">
            <div className="email-from-container">
                <span className="email-from-label">From</span>
                <FormControl size="small" className="email-from-form-control" fullWidth>
                    <Select
                        variant="outlined"
                        value={email.fromSender.email}
                        onChange={e => 
                            email.fromSender.email !== e.target.value && 
                            updateEmail({
                                fromSender: { 
                                    email: e.target.value, 
                                    name: fromEmailAddresses.find(a => a.fromEmailAddress === e.target.value)?.fromEmailName ?? ""
                                } 
                            })
                        }
                    >
                        {
                            fromEmailAddresses && 
                            fromEmailAddresses.map((address, idx) =>
                                <MenuItem value={address.fromEmailAddress} key={idx}>
                                    <div className="email-sender-menu-item-container">
                                        <span className="name">{truncateText(address.fromEmailName, 30)}</span>
                                        <span className="email">{truncateText(address.fromEmailAddress, 50)}</span>
                                    </div>
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </div>
            <div className="reply-email-container">
                <HoverText label="Reply email" labelContainerClassName="reply-email-label">
                    Replies will be sent to this email address. If left blank, replies will be sent to the address provided in the 'From' field. 
                </HoverText>
                <TextField
                    size="small" 
                    className="reply-email-text-field" 
                    fullWidth 
                    value={replyEmailInnerValue}
                    onChange={e => setReplyEmailInnerValue(e.target.value)}
                    error={!isReplyEmailFieldFocussed && isReplyEmailFieldInvalid}
                    helperText={!isReplyEmailFieldFocussed && isReplyEmailFieldInvalid ? "Enter valid email" : undefined}
                    FormHelperTextProps={{sx: { marginLeft: 0 }}}
                    onBlur={_ => {
                        if (replyEmailInnerValue !== email.replyAddress) {
                            if (!isReplyEmailFieldInvalid)
                                updateEmail({ replyAddress: replyEmailInnerValue });
                            else
                                updateEmail({ replyAddress: "" });
                        }

                        setIsReplyEmailFieldFocussed(false);
                    }}
                    onFocus={_ => setIsReplyEmailFieldFocussed(true)}
                />
            </div>
            <div className="to-container">
                <span className="to-label">To</span>
                <div className="recipient-field-container">
                    <RecipientCountDownload
                        onSaveEmail={onSaveEmail}
                        onSuccessMessage={onSuccessMessage}
                        onErrorMessage={onErrorMessage}
                    />
                    <EditAudiences
                        buttonProps={{sx: { paddingLeft: 0 }}}
                        buttonLabel="Add/Edit Audiences" 
                        audiences={email.audiences ?? []} 
                        availableAudiences={audiences} 
                        onChange={(audiences, isClear) => 
                            isClear 
                                // if its an update via 'Clear All', then clear the 'sendToAllUsers' as well
                                ? updateEmail({ audiences, sendToAllUsers: false })
                                // only update audiences if we don't have 'sendToAllUsers' as true
                                : !email.sendToAllUsers ? updateEmail({ audiences }) : undefined
                            }
                        extraFooterComponent={
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={email.sendToAllUsers} 
                                        size="small" 
                                        onChange={(_, checked) => updateEmail({ sendToAllUsers: checked, audiences: [] })}
                                    />
                                }
                                label="Send to all employees" 
                            />
                        }
                        disableAllSelections={email.sendToAllUsers}
                    />
                    <div>
                        <ChipList 
                            items={email.audiences ?? []} 
                            defaultLabel={email.sendToAllUsers ? "All users" : "No recipients selected"}
                            showEmptyChip
                            onClose={a => updateEmail({ audiences: email.audiences?.filter(audience => audience.id !== a.id) })}
                            truncateAtIndex={4}
                            closable
                        />
                    </div>
                </div>
            </div>
            <Divider className="authoring-divider"/>
            <div className="send-on-container">
                <HoverText label="Send on" labelContainerClassName="send-on-label-container">
                    Schedule when this email should be sent. If left blank, it will be sent immediately after you click 'Send' in One Last Check.
                </HoverText>
                <DateAndTime
                    clearable
                    onChangeDate={dateString => updateEmail({ sentTime: dateString ? new Date(dateString).toISOString() : undefined })}
                    date={email.sentTime}
                    dateStyle={{ width: 250 }}
                    showIcons={true}
                    minDate={moment()}
                />
            </div>
            <Divider className="authoring-divider"/>
            <div className="send-test-email-container">
                <div className="send-test-email-label-button-container">
                    <HoverText label="Send test email">
                        Send test emails to preview how your message will appear to recipients.
                    </HoverText>
                    <div className="send-test-email-loading-button-container">
                        { isSendingTestEmail && <LoadingCircle size="16px" style={{ marginRight: 4 }}/> }
                        <Button 
                            variant="outlined" 
                            disabled={testEmails.length === 0 || isSendingTestEmail}
                            onClick={onClickSendTestEmails}
                        >
                            Send test email
                        </Button>
                    </div>
                </div>
                <TextField 
                    className="test-email-address-text-field" 
                    size="small" 
                    placeholder="Enter up to 10 mail addresses" 
                    fullWidth
                    FormHelperTextProps={{sx: { marginLeft: 0 }}}
                    disabled={testEmails.length >= 10}
                    onKeyDown={handleKeyPress}
                    value={emailAddressValueForTest}
                    onChange={e => {
                        if (e.target.value !== ",") {
                            setEmailAddressValueForTest(e.target.value);
                            setShowInvalidForEmailTest(false);
                        }
                    }}
                    error={showInvalidForEmailTest}
                    helperText={showInvalidForEmailTest ? "Enter valid email" : <i>Separate email addresses with commas</i>}
                />
                <Chips 
                    chips={
                        testEmails.map((a, idx) => 
                            ({
                                id: `${idx}`,
                                name: a,
                                onDelete: () => setEmailAddressesForTest(testEmails.filter(addr => addr !== a))
                            })
                        )
                    } 
                    hideAll
                />
            </div>
        </div>
    </DrawerWithBookmarkButton>;
};

export default EmailSettingsPanel;

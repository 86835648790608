import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Loading from "modules/common/components/loading";
import { IdNamePair } from "modules/common/models";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { Address } from "modules/newsletters/models";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";

import "../../styles/associatedNewslettersDialog.sass";

interface IAssociatedNewslettersDialogProps {
    show: boolean;
    onClose: () => void;
    associatedNewsletters: IdNamePair<string, string>[];
    isLoading: boolean;
    senderAddressCheck?: Address;
    backButtonLabel: string;
    backButtonProps?: ButtonProps;
}

const AssociatedNewslettersDialog: React.FC<PropsWithRedux> = ({
    show,
    onClose,
    associatedNewsletters,
    isLoading,
    senderAddressCheck,
    tenantId,
    backButtonLabel,
    backButtonProps
}) => {
    const associatedNewslettersMap = (): JSX.Element[] =>
        associatedNewsletters.map(newsletter =>
            <li key={newsletter.id}>
                <span
                    onClick={() => {
                        const location: string = window.location.href;
                        const segments: string[] = location.split(tenantId);
                        window.open(`${segments[0]}${tenantId}/admin/newsletters/${newsletter.id}/dashboard`);
                    }}
                    className="newsletters-item"
                >
                    {newsletter.name}
                </span>
            </li>
        );
    
    return <Dialog open={show} className="associated-newsletters-dialog">
        <DialogTitle className="associated-newsletters-dialog-title">
            <div className="sender-title-container">
                Sender Email in Use
                <IconButton onClick={onClose} size="small" edge="end">
                    <CloseIcon/>
                </IconButton>
            </div>
        </DialogTitle>
        <DialogContent className="associated-newsletters-dialog-content">
            {
                isLoading &&
                <div className="loading-container">
                    <Loading padding={12}/>
                </div>
            }
            {
                !isLoading && associatedNewsletters.length > 0 &&
                <>
                    <div className="sender-description-container">
                        <b>{senderAddressCheck?.fromEmailName ?? ""}</b>, <b>{senderAddressCheck?.fromEmailAddress ?? ""}</b> cannot be deleted. The email is currently used in:
                    </div>
                    <ul className="newsletters-list">
                        {associatedNewslettersMap()}
                    </ul>
                </>
            }
        </DialogContent>
        <DialogActions className="associated-newsletters-dialog-actions">
            <Button color="primary" variant="contained" onClick={onClose} {...backButtonProps}>
                {backButtonLabel}
            </Button>
        </DialogActions>
    </Dialog>;
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IAssociatedNewslettersDialogProps) => 
    ({ 
        ...ownProps,
        tenantId: state.tenant.id,
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
  
export default connector(AssociatedNewslettersDialog);

import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { IPersonalizationToken } from "modules/emails/models";

import "../../styles/personalizationTokenButton.sass";

interface IPersonalizationTokenButtonProps {
    onClick: (token: IPersonalizationToken) => void;
    personalizationTokens: IPersonalizationToken[];
}

const PersonalizationTokenButton: React.FC<IPersonalizationTokenButtonProps> = ({
    onClick,
    personalizationTokens
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClick = (token: IPersonalizationToken) => {
        onClick(token);
        handleClose();
    }

    const handleClose = () => setAnchorEl(null);

    return <div className="personalization-token-button-container">
        <IconButton
            id="personalization-token-button"
            aria-controls={open ? 'personalization-token-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleButtonClick}
        >
            <img src="/images/icons/user_attributes_icon.svg" alt="user attributes icon" />
        </IconButton>
        <Menu
            id="personalization-token-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'personalization-token-button',
            }}
        >
            {
                personalizationTokens.map(token => 
                    <MenuItem key={token.value} onClick={() => handleClick(token)} className="personalization-token-menu-item">
                        <span className="personalization-token-title">{token.title}</span>
                        <span className="personalization-token-description">{token.description}</span>
                    </MenuItem>
                )
            }
        </Menu>
    </div>;
};

export default PersonalizationTokenButton;

import React from "react";
import "../styles/plainPreview.sass";

interface IPlainPreviewProps {}

const PlainPreview: React.FC<IPlainPreviewProps> = ({ children }) => {
    return <div className="plain-preview-container">
        <div className="plain-preview-content">
            <div className="plain-preview-modal">
                {children}
            </div>
        </div>
    </div>
};

export default PlainPreview;

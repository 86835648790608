import React from "react";
import { IEmail, } from "../models";
import moment from "moment";
import Body from "modules/events/components/event-views/components/body";
import { useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { replaceTextPersonalizationTokens } from "utils/replaceTextPersonalizationTokens";

import "../styles/emailContentView.sass";

interface IEmailContentViewProps {
    email: IEmail;
    activeLcid: string;
}

const EmailContentView: React.FC<IEmailContentViewProps> = ({
    email,
    activeLcid
}) => {
    const currentUser = useSelector((state: GlobalApplicationState) => state.settings.currentUser);

    return <div className="email-content-view">
        <div style={{ backgroundColor: "#ffffff" }}>
            <div className="email-page-content">
                <div className="subject">{email.subject ? replaceTextPersonalizationTokens(email.subject, activeLcid, currentUser) : "No subject"}</div>
                <div className="email-header-container">
                    <div className="sender">
                        <img className="sender-image" alt="Placeholder image for a user" src="/images/email-user-placeholder.png"/>
                        <div className="sender-info-container">
                            <div className="sender-name">
                                <b>{email.fromSender.name}</b>
                                <span className="info-span">{` <${email.fromSender.email}>`}</span>
                            </div>
                            <span className="info-span">to me</span>
                        </div>
                    </div>
                    <div className="email-action-buttons">
                        <div className="mock-buttons">
                            <img className="reply-icon" alt="Reply icon" src="/images/icons/reply_icon.svg"/>
                            <img className="reply-all-icon" alt="Reply all icon" src="/images/icons/reply_all_icon.svg"/>
                            <img className="forward-icon" alt="Forward icon" src="/images/icons/reply_icon.svg"/>
                        </div>
                        <div className="info-span">{
                            email.sentTime && new Date(email.sentTime) >= new Date()
                                ? moment(email.sentTime).format("ddd, MMM D, YYYY [at] h:mm A")
                                : moment().calendar()
                        }</div>
                    </div>
                </div>
                <Body body={replaceTextPersonalizationTokens(email.body, activeLcid, currentUser)}/>
            </div>
        </div>
    </div>;
};

export default EmailContentView;

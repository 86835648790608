import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoHover from "modules/common/components/hovers/infoHover";
import EditEmailSender from "modules/newsletters/management/components/editEmailSender";
import { connect, ConnectedProps } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { actions, Address, NewsletterConfigDigest, SaveNewsletterConfigModelStateErrors } from "modules/newsletters";
import Loading from "modules/common/components/loading";

import "../styles/senderEmailsDialog.sass";

interface ISenderEmailsDialogProps {
    show: boolean;
    onClose: () => void;
    onErrorMessage: (errorMessage: string) => void;
    onCheckAssociatedNewsletters: (address: Address) => void;
}

const SenderEmailsDialog: React.FC<PropsWithRedux> = ({
    show,
    onClose,
    onErrorMessage,
    getConfig,
    saveConfig,
    onCheckAssociatedNewsletters
}) => {
    const [config, setConfig] = useState<NewsletterConfigDigest | undefined>();
    const [modelStateSaveErrors, setModelStateSaveErrors] = useState<SaveNewsletterConfigModelStateErrors | undefined>();
    const [savingConfig, setSavingConfig] = useState(false);

    const isLoading = !config || savingConfig;

    useEffect(() => {
        const getAsyncConfig = async () => {
            var configDigest = await getConfig();
            setConfig(configDigest);
        }

        getAsyncConfig();
    }, []);

    const onSaveAddresses = async (addresses: Address[]) => {
        setSavingConfig(true);
        const newConfig = {...config!, addresses};
        const resp = await saveConfig(newConfig);
        
        if (resp.success) setConfig(resp.success);
        else if (resp.error?.ModelState) setModelStateSaveErrors(resp.error.ModelState);

        setSavingConfig(false);
    }

    return <Dialog
        open={show}
        onClose={onClose}
        className="sender-emails-dialog"
        classes={{ paper: "sender-emails-dialog-paper" }}
    >
        <DialogTitle className="sender-emails-dialog-title">
            <div className="title-text-container">
                Manage Sender Emails
                <InfoHover>
                    List of all 'From' names and addresses of your emails and newsletters. You cannot remove emails currently used in emails and newsletter.
                </InfoHover>
            </div>
            <IconButton onClick={onClose} size="large" edge="end">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className="sender-emails-dialog-content">
            {
                isLoading 
                ? <Loading/>
                : <EditEmailSender
                    config={config}
                    onErrorMessage={onErrorMessage}
                    onSaveAddresses={onSaveAddresses}
                    modelErrors={modelStateSaveErrors}
                    onCheckAssociatedNewsletters={onCheckAssociatedNewsletters}
                    listSectionStyles={{ padding: 0, border: "none", width: "100%" }}
                    emailSearchStyles={{ margin: 0, width: "100%" }}
                />
            }
        </DialogContent>
    </Dialog>;
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: ISenderEmailsDialogProps) => 
    ({ ...ownProps }),
    {
        getConfig: actions.getConfig,
        saveConfig: actions.saveConfig
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
  
export default connector(SenderEmailsDialog);

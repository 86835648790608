import React from "react";
import { TextField } from "@mui/material";
import DatePicker from "./datePicker";
import moment from "moment";
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import "../styles/switchAndCollapse.sass";
import { DateView } from "@mui/x-date-pickers";

interface IDateAndTimeProps {
    date?: string;
    label?: string;
    datePlaceholder?: string;
    timePlaceholder?: string;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
    containerStyle?: React.CSSProperties;
    dateStyle?: React.CSSProperties;
    timeStyle?: React.CSSProperties;
    clearable?: boolean;
    views?: DateView[];
    avoidPickerViewLayoutShift?: boolean;
    onChangeDate: (value: string | undefined) => void;
    showIcons?: boolean;
}

const DateAndTime: React.FunctionComponent<IDateAndTimeProps> = ({
    date,
    label,
    minDate,
    maxDate,
    datePlaceholder,
    dateStyle,
    timeStyle,
    timePlaceholder,
    views,
    clearable = false,
    avoidPickerViewLayoutShift,
    onChangeDate,
    showIcons = false,
}) => {
    const timeDisabled = Boolean(date) === false;

    const onChangeTimeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
        const times: string[] = event.target.value.split(":");
        const publishTime: string = moment(date)
            .set("hour", Number(times[0]))
            .set("minutes", Number(times[1]))
            .toISOString();

        onChangeDate(publishTime);
    }

    const getTime = (): string | undefined | null => {
        let result: string | undefined | null = "";
        if (Boolean(date))
            result = moment(date).format("HH:mm");

        return result;
    }

    return (
        <>
            <div className="date-time-content">
                {label && <label className="picker-label">{label}</label>}
                <DatePicker
                    avoidPickerViewLayoutShift={avoidPickerViewLayoutShift}
                    views={views}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChangeDate={onChangeDate}
                    date={date}
                    placeholder={datePlaceholder}
                    style={dateStyle}
                    clearable={clearable}
                    inputEndAdornment={showIcons ? <EventIcon fontSize="small" color="primary"/> : undefined}
                />
                <TextField
                    className="time-picker"
                    style={{
                        ...timeStyle,
                    }}
                    sx={{
                        // https://stackoverflow.com/questions/69742543/change-the-clock-icon-color-in-mui-textfield-with-type-time
                        '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            filter:
                                'invert(42%) sepia(20%) saturate(1375%) hue-rotate(165deg) brightness(96%) contrast(89%);',
                        },
                      }}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type="time"
                    placeholder={timePlaceholder ?? "Select time"}
                    value={getTime()}
                    disabled={!Boolean(date)}
                    inputProps={{
                        step: 900,
                    }}
                    InputProps={{
                        style: {
                            backgroundColor: timeDisabled ? "#f2f2f2" : "#ffffff"
                        },
                        endAdornment: showIcons && !Boolean(date) ? <ScheduleIcon fontSize="small" color="primary"/> : undefined
                    }}
                    onChange={onChangeTimeInternal}
                />
            </div>
        </>
    );
}

export default DateAndTime;

import Cookies, { CookieAttributes } from "js-cookie";

const COOKIE_OPT_IN_EXPIRY_DAYS = 365;
const DEFAULT_ROWS_PER_PAGE = 10;

const COMMON_COOKIE_KEYS = {
    ADMIN_DRAWER_EXPANDED: "sc-admin-drawer-expanded",
    USER_DRAWER_EXPANDED: "sc-user-drawer-expanded",
    MOBILE_APP_BANNER_CLOSED: "sc-mobile-app-banner-closed",
    GUIDELINE_PREVIEWED: "guideline-previewed",
    POST_AVG_IS_LOCAL: "post-averages-islocal",
    OPT_IN: "cookies-opt-in",
    PORTAL_PAGES_VISITED: "portal-pages-visited",
    USER_TUTORIAL_VISITED: "users-tutorial-visited"
};

const ROWS_PER_PAGE_COOKIE_NAMES = {
    DEFAULT: "rows-per-page",
    GALLERY: "gallery-rows-per-page",
    EMAILS: "emails-rows-per-page",
};

const setWithOpts = (name: string, value: string, opts: CookieAttributes) => {
    if (Cookies.get("cookies-opt-in") === "true")
        Cookies.set(name, value, opts);
}

export default {
    getPrefixedKey(prefix: string, key: string) {
        return `${prefix}-${key}`;
    },

    getRowsPerPage(cookieName: string = ROWS_PER_PAGE_COOKIE_NAMES.DEFAULT, defaultRowsPerPage: 10 | 25 | 50 | 100 = DEFAULT_ROWS_PER_PAGE): number {
        let rowsPerPage = Cookies.get(cookieName);
        return rowsPerPage ? +rowsPerPage : defaultRowsPerPage;
    },

    getMobileAppBannerClosed(userEmail: string) {
        let value = Cookies.get(this.getPrefixedKey(userEmail, COMMON_COOKIE_KEYS.MOBILE_APP_BANNER_CLOSED));
        return value;
    },

    getTenantAveragesSetting() {
        let isUsingTenant = Cookies.get(COMMON_COOKIE_KEYS.POST_AVG_IS_LOCAL) === "true";
        return isUsingTenant;
    },

    setTenantAveragesSetting(toSet: boolean) {
        this.set(COMMON_COOKIE_KEYS.POST_AVG_IS_LOCAL, `${toSet}`);
    },

    get: (name: string) => {
        return Cookies.get(name);
    },

    remove: (name: string) => {
        Cookies.remove(name);
    },
    
    isFirstSet(name: string) {
        if (Cookies.get(name) === "true") return false;
        
        this.setWithOpts(name, "true", {expires: 365});
        return true;
    },

    //Expires = Session by default. Use setWithOps to set a expiry time.
    set: (name: string, value: string) => {
        if (Cookies.get(COMMON_COOKIE_KEYS.OPT_IN) === "true")
            Cookies.set(name, value);
    },

    setWithOpts: (name: string, value: string, opts: CookieAttributes) => {
        if (Cookies.get(COMMON_COOKIE_KEYS.OPT_IN) === "true")
            Cookies.set(name, value, opts);
    }
};

export { COOKIE_OPT_IN_EXPIRY_DAYS, COMMON_COOKIE_KEYS, ROWS_PER_PAGE_COOKIE_NAMES };

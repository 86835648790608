export const enUS = JSON.parse(`
{
  "activity.activity": "Activity",
  "activity.commentInfo": "Comment Information",
  "activity.actions": "Actions",
  "activity.flaggingLastIgnored": "Flagging Last Ignored On",
  "activity.totalFlags": "Total Flags",
  "activity.flagsLastIgnored": "Flags Since Last Ignored",
  "activity.itemError": "There was an error loading this item, it no longer exists",

  "audiences.audiences": "Audiences",
  "audience.audience": "Audience",
  "audience.details": "Audience Details",
  "audience.users": "Users in Audience",
  "audience.addUsers": "Add Users to Audience",
  "audience.noItems": "No items in audience yet",
  "audiences.everyoneAudience": "Everyone Audience",
  "audience.groupsInEveryoneAudience": "Sync Groups in Everyone Audience",
  "audience.addGroupsToEveryoneAudience": "Add {syncType} Groups to Everyone Audience",
  "audiences.badRequestError": "There was an error sending your request",
  "audiences.internalServerError": "There was an error processing your request, please contact support.",
  "audience.resyncBtnMsg": "Resync All {syncType} Users",
  "audience.resyncBtnMsgInProg": "Resync in progress..",

  "categorytags.categoryTags": "Category Tags",

  "common.signin": "Sign In",
  "common.signout": "Sign Out",
  "common.dashboard": "Dashboard",
  "common.description": "Description",
  "common.SPARROW": "SPARROW",
  "common.product": "Product",
  "common.pricing": "Pricing",
  "common.contact": "Contact",
  "common.error:": "Error:",
  "common.timeError": "Not a valid time",
  "common.errorLoadingComponent": "There was an error loading the component",
  "common.accessNotAllowed": "You are not allowed to access this content.",
  "common.contactAdminForPermission": "Contact your administrator to request permission.",
  "common.404": "404 - Page Not Found",
  "common.comments": "Comments",
  "common.noComments": "No Comments",
  "common.topics": "Topics",
  "common.restrictedTopics": "Restricted Topics",
  "common.allTopics": "All Topics",
  "common.viewAllTopics": "View All Topics",
  "common.filterTopicsByName": "Filter topics by name",
  "common.title": "Title",
  "common.created": "Created",
  "common.name": "Name",
  "common.fetching": "Fetching",
  "common.mandatoryTopic": "Mandatory Topic",
  "common.owner": "Owner",
  "common.user": "User",
  "common.author": "Author",
  "common.dismiss": "Dismiss",
  "common.cancel": "Cancel",
  "common.save": "Save",
  "common.refresh": "Refresh",
  "common.continue": "Continue",
  "common.delete": "Delete",
  "common.deleting": "Deleting",
  "common.edit": "Edit",
  "common.loading": "Loading",
  "common.privateTopic": "Private Topic",
  "common.publicTopic": "Public Topic",
  "common.all": "All",
  "common.select": "Select",
  "common.loadMore": "Load More",
  "common.tryAgain": "Try Again?",
  "common.commentAuthorLine": "<i>{author}</i> on {date}",
  "common.xMore": "{x, number} more",
  "common.default": "Default",
  "common.makeDefault": "Make Default",
  "common.limitNum": "Limit {num, number}",
  "common.link": "Link",
  "common.code": "Code",
  "common.flaggedItems": "Flagged Comments",
  "common.activityLog": "Activity Log",
  "common.enable": "Enable",
  "common.disable": "Disable",
  "common.enableGroup": "Enable group",
  "common.disableGroup": "Disable group",
  "common.ignore": "Ignore",
  "common.close": "Close",
  "common.noResults": "No results",
  "common.selectOrganization": "Select your organization",
  "common.switchOrganizations": "Switch organizations",
  "common.go": "Go",
  "comment.edited": "[Edited] ",
  "common.color": "Color",
  "common.accountDisabled": "Your account may have been disabled. Please talk to your Sparrow Administrator.",
  "common.manageUserSubscriptions": "Manage User Subscriptions",
  "common.topicCreation": "Create New Topic",
  "common.mandatoryTopic": "Users in the selected audiences will always be subscribed to this topic. If no audiences are selected, all users will always be subscribed.",
  "common.defaultTopic": "New users in the selected audiences will always be subscribed to this topic by default. If no audiences are selected, all new users will be subscribed by default.",
  "common.restrictedTopic": "Users must be a part of the selected audiences to see this topic. Restricted topics must have at least one audience.",
  "common.assemblingAnalytics": "Assembling Your Analytics",
  "common.sharepoint": "SharePoint",
  "common.teams": "Teams",
  "common.mobile": "Mobile",
  "common.adminPortal": "Admin Portal",
  "common.userPortal": "User Portal",
  "common.userPortalLink": "Go to Company Portal",
  "common.data": "Data",
  "common.type": "Type",


  "editor.embedMedia": "Embed media",
  "editor.image": "Insert inline image",
  "editor.intenseEmphasis": "Apply intense emphasis to link",
  "editor.emphasis": "Intense Emphasis",

  "events.events": "Events",

  "flagging.deleteComment": "Delete Comment",
  "flagging.ignoreFlags": "Ignore Flags",
  "flagging.goToPost": "Go to Post",


  "gallery.gallery": "Gallery",

  "insights.postsWithTopic": "Posts with topic {tagName}",
  "insights.positive": "Positive",
  "insights.veryPositive": "Very Positive",
  "insights.neutral": "Neutral",
  "insights.negative": "Negative",
  "insights.veryNegative": "Very Negative",
  "insights.insights": "Insights",
  "insights.insightsAndReports": "Insights & Reports",
  "insights.downloadComplianceReport": "Compliance Report",
  "insights.downloadUserReadsReport": "User Read Report",
  "insights.downloadCSVReport": "Download CSV Report",
  "insights.downloadAsExcel": "as Excel",
  "insights.downloadAsImg": "as Images",
  "insights.mostViewedPosts": "Most Viewed Posts",
  "insights.allTime": "All Time",
  "insights.lastMonth": "Last Month",
  "insights.lastWeek": "Last Week",
  "insights.viewAllPosts": "Go to Manage Posts",
  "insights.commentsByMonth": "Comments by Month",
  "insights.notEnoughData": "Not enough data",
  "insights.appUsage": "App Usage",
  "insights.readsByPlatform": "Reads By Platform",
  "insights.overallSentimentScore": "Overall Sentiment Score: ",
  "insights.readAnalysis": "Read Analysis",
  "insights.percentOfPostRead": "% of post read",
  "insights.percentOfReaders": "% of readers",
  "insights.postPartialReadPercent": "{pctReaders, number, percent} of readers stopped reading before the {readMark, number, percent} mark of the post.",
  "insights.postFullReadPercent": "{pctReaders, number, percent} of readers read this post all the way to the end.",
  "insights.readsByHour": "Views by Time of Day",
  "insights.firstWeekExposure": "First Week Exposure",
  "insights.reads": "Reads",
  "insights.views": "Views",
  "insights.awareness": "Awareness",
  "insights.subscribers": "Subscribers",
  "insights.awarenessOverTime": "Awareness Over Time",
  "insights.topicAwarenessXLabel": "days since first post was created with the {tagName} topic",
  "insights.daysSincePublishedXLabel": "days since post was published",
  "insights.awarenessDescription": "Awareness of this topic increases as users read posts marked with the {tagName} topic. Post read contribution depends on how much of the post was read by the user and decays over time. In order to maintain awareness of this topic in your organization, posts should be created at regular intervals to keep users engaged.",
  "insights.mostRespondedSurveys": "Surveys With the Most Responses",
  "insights.mostSubscribedTopics": "Most Subscribed Topics",
  "insights.activeUsers": "Active Users",
  "insights.activeUsersByMonth": "Active Users by Month",
  "insights.userTrend": "User Trend",
  "insights.usersInMonth": "Users in {month}",
  "insights.userTrendComparisonFewer": "Compared with the previous month, {prevMonth}, you had {difference} fewer active users in {thisMonth}.",
  "insights.userTrendComparisonGreater": "Compared with the previous month, {prevMonth}, you had {difference} additional active users in {thisMonth}.",
  "insights.userTrendComparisonSame": "Compared with the previous month, {prevMonth}, you had about the same number of active users in {thisMonth}.",
  "insights.welcomeJustGettingStarted": "Welcome to Sparrow! It looks like you're just getting started.",
  "insights.englishOnly" : "Currently only available for English posts",
  "insights.defaultLanguageOnly" : "Currenly only available for posts in default language",

  "localization.addLanguage": "Add Language",
  "localization.languages": "Languages",
  "localization.removeLanguage": "Remove Language",
  "localization.translateLanguage": "Translate",

  "logout.signingOut": "Signing you out...",
  "logout.signedOut": "You are signed out.",

  "messaging.directMessaging": "Direct Messaging",
  "messaging.newsletters": "Newsletters",
  "messaging.emails": "Emails",

  "posts.posts": "Posts",
  "posts.allPosts": "All Posts",
  "posts.filterByTitle": "Filter posts by title",
  "posts.views": "Views",
  "posts.dateCreated": "Date Created",
  "posts.fetchingPosts": "Fetching Posts...",
  "posts.reactions": "Reactions",
  "posts.noReactions": "No reactions yet",
  "posts.totalReactions": "Total Reactions",
  "posts.totalReactionsDescription": "The most recent sum of all reactions for this post.",
  "posts.authors": "Authors",
  "posts.author": "Author",
  "posts.noauthor": "No Author",
  "posts.dragPrompt": "Drag to Reposition, Scroll to Zoom",
  "posts.authorsEmail": "Author's E-mail",
  "posts.authorsEmailHint": "Readers can send their questions about this post to this email.",
  "posts.authorFieldHint": "Example: John Doe, or Sales Department",
  "posts.postContentRequired": "Post content is required",
  "posts.contentTip": "Your users' average drop off rate is <b>about {idealWordCount, number} words</b>. For best results aim to keep posts short.",
  "posts.cropImage": "Crop Image",
  "posts.selectImage": "Select an Image",
  "posts.select1TopicMin": "Select at least one topic",
  "posts.warningMixedTopics": "Warning: You have mixed a Restricted and Non-Restricted topic. This may make the content available to unintended users.",
  "posts.chooseFile": "Choose a file",
  "posts.chooseFileElsewhere": "Choose a file from somewhere else",
  "posts.oneFileAtATime": "You can only upload one file at a time.",
  "posts.noFilesFound": "No files found.",
  "posts.unrecognizedFileType": "File type not recognized: {fileType}",
  "posts.longTitleWarning": "Note: Longer titles have a chance of being cut off in some views.",
  "posts.titleRequired": "{type} is Required",
  "posts.upload": "Upload",
  "posts.useExisting": "Use Existing Media",
  "posts.useExistingDocuments": "Use Existing Documents",
  "posts.noImagesFound": "No images found.",
  "posts.noDocumentsFound": "No documents found.",
  "posts.uploading": "Uploading",
  "posts.failedUpload": "Failed to Upload",
  "posts.uploadCancelled": "Upload Cancelled",
  "posts.uploadAgain": "Upload Again?",
  "posts.imageSizeLimit": "Images must be smaller than {size}",
  "posts.videoSizeLimit": "Videos must be smaller than {size}",
  "posts.documentSizeLimit": "Documents must be smaller than {size}",
  "posts.pdfSizeLimit": "Pdfs must be smaller than {size}",
  "posts.untitledPost": "Untitled Post",
  "posts.notPublished": "Not Published",
  "posts.publishedFromExternal": "Published from External",
  "posts.noTopicsSelected": "No Topics Selected",
  "posts.uniqueOpens": "Unique Opens",
  "posts.uniqueOpensDescription": "The total number of unique users who have opened this post (across all platforms).",
  "posts.completeReads": "Complete Reads",
  "posts.completeReadsDescription":"The total number of times the post was viewed in its entirety.",
  "posts.noContent": "No Content",
  "posts.selectMedia": "Select Media",
  "posts.selectDocuments": "Select Documents",
  "posts.confirmImageDelete": "Are you sure you want to delete {count, plural, one {# image} other {# images}}?",
  "posts.confirmReplaceContent": "Are you sure you want to replace the exisiting content?",
  "posts.totalViews": "Total Views",
  "posts.totalViewsDescription": "The total number of times this post has been opened.",
  "posts.compliedUsers": "Complied Users",
  "posts.compliedUsersDescription" : "The most recent sum of all users who have complied to this post.",
  "posts.completeUniqueReads": "Complete Unique Reads",
  "posts.completeUniqueReadsDescription": "The total number of unique users who have viewed this post in its entirety.",
  "posts.standardDescription": "This post will only show up in the timelines of people subscribed to its topics.",
  "posts.complianceDescription": "This post will give readers the ability to acknowledge they have read the post. Compliance posts cannot be changed once published.",
  "posts.mandatoryDescription": "This post will display with a mandatory icon in users timeline.",
  "posts.publicDescription": "This post will be visible to users outside your organization.",
  "posts.publishedBy": "Published by",
  "posts.author": "Author",
  "posts.published": "Published on",
  "posts.state": "State",
  "posts.lastModified": "Last modified on",
  "posts.viewPost": "View Post",
  "posts.preview": "Preview",
  "posts.bannersSet": "Set as",
  "posts.compliance": "Compliance",

  "posts.notificationsUponPublishing": "Notifications upon publishing",
  "posts.notificationsLastSent": "Last sent on",

  "posts.analysisMinutes" : "minutes",
  "posts.analysisSeconds" : "seconds",
  "posts.postAnalysis" : "Post Analysis",
  "posts.analysisEnglishOnly" : "Content Analysis is provided for English content only.",
  "posts.analysisWordCount" : "Word Count",
  "posts.analysisReadingTime" : "Estimated Reading Time",
  "posts.analysisTone" : "Tone",
  "posts.sentiment" : "Sentiment",
  "posts.analysisGunningFog": "Education Level for Easy Comprehension",
  "posts.analysisIELTS": "English Proficiency Required",
  "posts.analysisReadabilityScore": "Readability Score",
  "posts.analysisIdeal": "(Ideal)",
  "posts.keyInsights": "Key Insights",
  "posts.readingAnalysis": "Content Analysis",
  "posts.postInsights": "Post Insights",
  "posts.channelBreakdown": "Channel Breakdown",
  "posts.languageBreakdown": "Language Breakdown",


  "settings.settings": "Settings",

  "support.support": "Support",
  "support.question": "Question",
  "support.releaseName": "Release Name",
  "support.releaseNumber": "Release Number",
  "support.releaseNumberRequired": "Release Number is Required",
  "support.uploadAFile": "Upload a File",
  "support.training": "Training",
  "support.active": "Active",
  "support.resolved": "Resolved",
  "support.availableIn": "Available in: ",
  "support.category": "Category: ",
  "support.lastUpdated": "Last Updated: ",
  "support.attachedMedia": "Attached Media: ",
  "support.faq": "FAQ",
  "support.knownIssue": "Known Issue",
  "support.releaseNote": "Release Note",
  "support.releasePackage": "Release Package",
  "support.itemType": "Support Item Type: ",

  "surveys.surveys": "Surveys",
  "surveys.viewAllSurveys": "Go to Manage Surveys",

  "events.viewAllEvents": "Go to Manage Events",


  "users.users": "Users",
  "users.groups": "User Groups",
  "users.syncedGroups": "{syncType} Groups",
  "users.bulkUpload": "Bulk Upload"
}`);

import InputField from 'modules/users/components/user-field-components/inputField';
import React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { ValueLabel } from 'modules/common/components/selectFilter';
import { ShareUrlEvents, TeamsEvents, actions } from '../..';
import Select from 'react-select';
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SettingsToggleOnly from '../toggles/settingsToggleOnly';
import { NotificationEvents } from 'modules/messaging';
import BlackoutToggleEvent from '../toggles/blackoutToggleEvent';
import InputFieldDouble from 'modules/users/components/user-field-components/inputFieldDouble';
import TextInputField from 'modules/users/components/user-field-components/textInputField';
import ShareUrlsToggleOnly from '../toggles/shareUrlsToggleOnly';
import ConditionalWrapper from 'modules/common/components/conditionalWrapper';
import CalloutHover from 'modules/common/components/hovers/calloutHover';

interface ComponentProps { }
interface ComponentState { 
    blackoutEmailDaysSelected: boolean;
    blackoutSMSDaysSelected: boolean;
    blackoutTeamsDaysSelected: boolean;
    blackoutMobileDaysSelected: boolean;
    reminderPostsDaysSelected: boolean;
    reminderEventsDaysSelected: boolean;

    linkPreference: string;
    postReminderNum: number;
    eventReminderNum: number;

    showPopoverTeams: boolean;
    showPopoverSP: boolean;
    anchorEl: HTMLDivElement | null;
    calloutMessage: string;

    nameFromUrl: string;
    sender: string;
}

class MessagingMainControls extends React.Component<PropsWithRedux, ComponentState> {

    constructor(props) {
        super(props)

        var startingNumPost;
        var startingNumEvent;
        var postsDaysSelected;
        var eventsDaysSelected;
        var numberSavedPost = this.props.notificationSettings.defaultPostReminder ?? 0;
        var numberSavedEvent = this.props.notificationSettings.defaultEventReminder ?? 0;

        if(numberSavedPost % 24 === 0)
        {
            startingNumPost = numberSavedPost / 24
            postsDaysSelected = true;
        }
        else
        {
            startingNumPost = numberSavedPost;
            postsDaysSelected = false;
        }

        if(numberSavedEvent % 24 === 0)
        {
            startingNumEvent = numberSavedEvent / 24
            eventsDaysSelected = true;
        }
        else
        {
            startingNumEvent = numberSavedEvent;
            eventsDaysSelected = false;
        }

        this.state = {
            blackoutEmailDaysSelected: false,
            blackoutSMSDaysSelected: false,
            blackoutTeamsDaysSelected: false,
            blackoutMobileDaysSelected: false,
            reminderEventsDaysSelected: eventsDaysSelected,
            reminderPostsDaysSelected: postsDaysSelected,           
            linkPreference: "adminPortal",
            postReminderNum: startingNumPost,
            eventReminderNum: startingNumEvent,
            nameFromUrl: this.props.notificationSettings.fromEmailAddress?.substring(0, this.props.notificationSettings.fromEmailAddress?.indexOf('@')) ?? "",
            sender: this.props.notificationSettings.fromEmailName ?? "",
            showPopoverTeams: false,
            showPopoverSP: false,
            anchorEl: null,
            calloutMessage: "",
        }
    }

    componentDidUpdate() {
        
        let eventNum = this.state.reminderEventsDaysSelected ? this.state.eventReminderNum * 24 : this.state.eventReminderNum;
        let postNum = this.state.reminderPostsDaysSelected ? this.state.postReminderNum * 24 : this.state.postReminderNum;

        let propEvent = this.props.notificationSettings.defaultEventReminder!;
        let propPost = this.props.notificationSettings.defaultPostReminder!;

        let urlName = this.props.notificationSettings.fromEmailAddress?.substring(0, this.props.notificationSettings.fromEmailAddress?.indexOf('@')) ?? ""

        if(eventNum !== this.props.notificationSettings.defaultEventReminder) {
            this.setState({eventReminderNum: propEvent % 24 === 0 ? propEvent / 24 : propEvent, 
                reminderEventsDaysSelected: propEvent % 24 === 0 ? true : false})
        }

        if(postNum !== this.props.notificationSettings.defaultPostReminder) {
            this.setState({postReminderNum: propPost % 24 === 0 ? propPost / 24 : propPost, 
                reminderPostsDaysSelected: propPost % 24 === 0 ? true : false})
        }

        if(this.state.nameFromUrl !== urlName)
        {
            this.setState({nameFromUrl: urlName})
        }

        if(this.state.sender !== this.props.notificationSettings.fromEmailName)
        {
            this.setState({sender: this.props.notificationSettings.fromEmailName ?? ""})
        }
    }

    public render() {
        const fullEmailAddress = this.props.notificationSettings.fromEmailAddress;
        const linkPreferenceCurrent = this.props.notificationSettings.linkPreference ?? "adminPortal";
        const addressFromUrl = "@"+fullEmailAddress?.split('@').pop();
        const location: string = window.location.href;

        let segments: string[] = [];
        segments = location.split(this.props.tenantId ?? "");

        const companyPortalPostsDefaultUrl = `${segments[0]}${this.props.tenantId}/posts/`;
        const companyPortalEventsDefaultUrl = `${segments[0]}${this.props.tenantId}/events/`;

        const blackoutTimeframeOptions = [
            { value: 'hours', label: 'Hours'},
            { value: 'days', label: 'Days'}
        ]

        const linkPreferenceOptions = [
            {value: 'none', label: 'None'},
            {value: 'adminPortal', label: 'Company Portal'},
            {value: 'sharePoint', label: 'MS Sharepoint'},
            {value: 'teams', label: "MS Teams"}
        ]

        const LightTooltip = withStyles((theme) => ({
            tooltip: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[1],
                fontSize: "12px"
            }
        }))(Tooltip);

        return (
            <div>
                <div className="settings-section">
                    <b>Sender Details</b>  
                    <p className="description-text">Change the sender details that employees see upon receiving emails or SMS. Contact Sparrow to customize your email address, domain, and mobile number.</p> 

                    <InputField
                        inputType={"text"}
                        title={"Name for Emails"}
                        value={this.state.sender}
                        onChange={(e) => this.props.updateEmailName(e)}
                        pristineText={this.props.pristine?.fromEmailName}
                        maxLength={500}
                    />

                    <InputFieldDouble
                        inputType={"text"}
                        title={"Sender Email"}
                        value={this.state.nameFromUrl}
                        onChange={(e) => this.props.updateEmailSender(e, addressFromUrl)}
                        disabledText={addressFromUrl}
                        pristineText={this.props.pristine?.fromEmailAddress?.substring(0, this.props.pristine?.fromEmailAddress?.indexOf('@')) ?? ""}
                        maxLength={100}
                        filter={"^[a-zA-Z1-9 .-]*$"}
                    />
                    
                    <InputField 
                        inputType={"text"}
                        title={"Mobile Number"}
                        isDisabled={true}
                        placeholder={"+1 833 684 1574"}
                    />
                </div>
            
                <div className="settings-section">
                    <b>Blackout periods</b>   
                    <p className="description-text">Avoid Email and SMS flooding by setting blackout periods.
                        <LightTooltip title="A blackout period is a temporary interval during which Email or SMS notifications will not be sent to subscribed employees.">
                            <ins className="tooltips-settings">Learn More.</ins>
                        </LightTooltip>
                    </p>

                    <div style={{paddingTop: "10px"}}>
                        <div className="settings-input-label">Emails</div>
                            <input 
                                className="settings-input-field-sm"
                                type="number" 
                                value={this.props.notificationSettings.defaultEmailBlackoutPeriod}
                                onChange={(e) => this.props.updateEmailBlackout(this.sanitizeInput(e.target.valueAsNumber), this.state.blackoutEmailDaysSelected)}
                                min={0}
                            ></input>
                            <input
                                type={"text"}
                                disabled={true}
                                placeholder={"Hours"}
                                className="disabled-placeholder-input"
                            ></input>
                    </div>                    
                    <div style={{paddingTop: "10px"}}>
                        <div className="settings-input-label">SMS</div>

                            <input 
                                className="settings-input-field-sm"
                                type="number" 
                                value={this.props.notificationSettings.defaultSMSBlackoutPeriod}
                                onChange={(e) => this.props.updateSMSBlackout(this.sanitizeInput(e.target.valueAsNumber), this.state.blackoutSMSDaysSelected)}
                                min={0}
                            ></input>
                            <input
                                type={"text"}
                                disabled={true}
                                placeholder={"Hours"}
                                className="disabled-placeholder-input"
                            ></input>
                    </div>
                    <div style={{paddingTop: "10px"}}>
                        <div className="settings-input-label">Teams</div>

                            <input 
                                className="settings-input-field-sm"
                                type="number" 
                                value={this.props.notificationSettings.defaultTeamsBlackoutPeriod}
                                onChange={(e) => this.props.updateTeamsBlackout(this.sanitizeInput(e.target.valueAsNumber), this.state.blackoutTeamsDaysSelected)}
                                min={0}
                            ></input>
                            <input
                                type={"text"}
                                disabled={true}
                                placeholder={"Hours"}
                                className="disabled-placeholder-input"
                            ></input>
                    </div>
                    <div style={{paddingTop: "10px"}}>
                        <div className="settings-input-label">Mobile</div>

                            <input 
                                className="settings-input-field-sm"
                                type="number" 
                                value={this.props.notificationSettings.defaultMobileBlackoutPeriod}
                                onChange={(e) => this.props.updateMobileBlackout(this.sanitizeInput(e.target.valueAsNumber), this.state.blackoutMobileDaysSelected)}
                                min={0}
                            ></input>
                            <input
                                type={"text"}
                                disabled={true}
                                placeholder={"Hours"}
                                className="disabled-placeholder-input"
                            ></input>
                    </div>
                </div>

                <div className="settings-section">
                    <div>
                        <b>Mass Messaging (Blasts)</b>
                        <p className="description-text">Disabling blasts and/or mass invites will hide and deactivate the mass messaging feature of the respective channel or invitation.</p>
                    </div>
                    <div>
                        <SettingsToggleOnly 
                            title={"SMS Blasts"}
                            emailEvent={NotificationEvents.MassSMS}
                            smaller={true}
                        />
                        {
                            this.props.notificationSettings.settings[NotificationEvents.MassSMS]?.toggle ? 
                                <BlackoutToggleEvent descriptor="Apply blackout period for SMS blasts" event={NotificationEvents.MassSMS}/> : 
                                <b></b>
                        }
                    </div>
                    <div>
                        <SettingsToggleOnly 
                            title={"Mass Consent Invitations"}
                            emailEvent={NotificationEvents.ConsentInviteSms}
                            smaller={true}
                        />
                        {
                            this.props.notificationSettings.settings[NotificationEvents.ConsentInviteSms]?.toggle ? 
                                <BlackoutToggleEvent descriptor="Apply blackout period for Mass Consent Invitation" event={NotificationEvents.ConsentInviteSms}/> : 
                                <b></b>
                        }
                    </div>

                    
                </div>

                <div className="settings-section">
                    <b>Default Reminder periods</b>   
                    <p className="description-text">If reminders are turned on, this will be the default period it will follow. You can change this under each setting under Posts, Documents, or Events Notifications/Reminders.</p>    

                    <div>
                        <div className="settings-input-label">Reminder period for Posts</div>
                            <input 
                                className="settings-input-field-sm"
                                type="number" 
                                onChange={(e) => 
                                    { this.setState({postReminderNum: this.sanitizeInput(e.target.valueAsNumber)}); 
                                      this.props.updatePostsReminders(this.sanitizeInput(e.target.valueAsNumber), this.state.reminderPostsDaysSelected)}}
                                value = {this.state.postReminderNum}
                                min = {0}
                            ></input>
                            <Select 
                                className="settings-input-field-select"
                                options={blackoutTimeframeOptions}
                                defaultValue={blackoutTimeframeOptions[0]}
                                dropdownIndicator={null}
                                onChange={this.handleChangePostReminder}
                                value={this.state.reminderPostsDaysSelected ? blackoutTimeframeOptions[1] : blackoutTimeframeOptions[0]}
                            />
                    </div>
                  
                    <div>
                        <div className="settings-input-label">Reminder period for Events</div>

                            <input 
                                className="settings-input-field-sm"
                                type="number" 
                                onChange={(e) => {this.setState({eventReminderNum: this.sanitizeInput(e.target.valueAsNumber)}); 
                                                  this.props.updateEventsReminders(this.sanitizeInput(e.target.valueAsNumber), this.state.reminderEventsDaysSelected)}}
                                value={this.state.eventReminderNum}
                                min = {0}
                            ></input>
                            <Select 
                                className="settings-input-field-select"
                                options={blackoutTimeframeOptions}
                                defaultValue={blackoutTimeframeOptions[0]}
                                dropdownIndicator={null}
                                onChange={this.handleChangeEventReminder}
                                value={this.state.reminderEventsDaysSelected ? blackoutTimeframeOptions[1] : blackoutTimeframeOptions[0]}
                            />
                    </div>

                </div>

                <div className="settings-section">
                    <div>
                        <b>Link Preferences</b>
                        <p className="description-text">Set the channels and pages where employees share and open content from.</p>

                        <div className="settings-input-label">Open notification and reminder links in</div>
                        <Select
                            className="settings-input-field-select-long"
                            options={linkPreferenceOptions}
                            defaultValue={linkPreferenceOptions[linkPreferenceCurrent]}
                            onChange={this.handleLinkPreferenceDropdown}
                            value={linkPreferenceOptions[this.props.notificationSettings.linkPreference!]}
                            menuPlacement="top"
                        />
                    </div>
                    <div className='share-div-padding'>
                        <ShareUrlsToggleOnly  
                            title={"Portal"}          
                            shareToggleEvent={ShareUrlEvents.toggleCompanyPortalUrl}        
                            postsShareUrlEvent={TeamsEvents.companyPortalUrl}
                            eventsShareUrlEvent={TeamsEvents.companyPortalEventsUrl}     
                            handleClick={ this.handleSwitchUpdates }
                        />
                        <p className="description-text">Use the URL of your portal's homepage or feeds as the landing page for the sharing or opening posts or events.</p>
                        <div className="input-text-padding">
                            <TextInputField
                                label={"Share Label"}
                                shareEvent={ShareUrlEvents.companyPortalUrlShareLinkName}  
                                value={this.props.shareUrlsConfig.companyPortalUrlShareLinkName}
                                onChange={this.handleShareNameTextFields}
                                placeholder='Portal Link'
                                maxLength={30}
                            />
                        </div>
                         <div className="input-text-padding">
                            <TextInputField
                                label={"Posts"}
                                value={this.props.teams.companyPortalUrl || companyPortalPostsDefaultUrl}
                                onChange={this.handleShareUrlTextFields}
                                shareEvent={TeamsEvents.companyPortalUrl}
                                placeholder='https://admin.sparrowconnected.com/{tenant}/posts/'
                            />
                        </div>
                        <div className="input-text-padding">
                            <TextInputField
                                label={"Events"}
                                value={this.props.teams.companyPortalEventsUrl || companyPortalEventsDefaultUrl}
                                onChange={this.handleShareUrlTextFields}
                                shareEvent={TeamsEvents.companyPortalEventsUrl}
                                placeholder='https://admin.sparrowconnected.com/{tenant}/events/'
                            />
                        </div>
                    </div>
                     <div className='share-div-padding'>
                     <ConditionalWrapper
                        condition={this.state.showPopoverSP}
                        wrapper={(children) => (
                            <CalloutHover
                                arrow
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                component={
                                <ShareUrlsToggleOnly 
                                title={"Intranet (MS SharePoint)"}     
                                shareToggleEvent={ShareUrlEvents.toggleSpUrl}
                                postsShareUrlEvent={TeamsEvents.spUrl}
                                eventsShareUrlEvent={TeamsEvents.spEventsUrl}   
                                handleClick={ this.handleSwitchUpdates }
                                onMouseOver={ this.checkSharePointOnMouseOver }               
                            />
                                }
                            >
                                <p>{this.state.calloutMessage}</p>
                            </CalloutHover>
                        )}
                    >
                        <ShareUrlsToggleOnly  
                            title={"Intranet (MS SharePoint)"}          
                            shareToggleEvent={ShareUrlEvents.toggleSpUrl}        
                            postsShareUrlEvent={TeamsEvents.spUrl}
                            eventsShareUrlEvent={TeamsEvents.spEventsUrl}     
                            handleClick={ this.handleSwitchUpdates }
                            onMouseOver={ this.checkSharePointOnMouseOver }
                        />
                    </ConditionalWrapper>
                        <p className="description-text">Use SharePoint page URLs with the View Post, News Central or Events Central webparts.</p>
                        <div className="input-text-padding">
                            <TextInputField
                                label={"Share Label"}
                                value={this.props.shareUrlsConfig.spUrlShareLinkName}
                                onChange={this.handleShareNameTextFields }
                                shareEvent={ShareUrlEvents.spUrlShareLinkName}
                                placeholder='Intranet Link'
                                maxLength={30}
                            />
                        </div>
                        <div className="input-text-padding">
                            <TextInputField
                                label={"Posts"}
                                value={this.props.teams.spUrl}
                                onChange={this.handleShareUrlTextFields}
                                shareEvent={TeamsEvents.spUrl}
                                placeholder='https://{tenant}.sharepoint.com/SitePages/News-Central.aspx'
                            />
                        </div>
                        <div className="input-text-padding">
                            <TextInputField
                                label={"Events"}
                                value={this.props.teams.spEventsUrl}
                                onChange={this.handleShareUrlTextFields}
                                shareEvent={TeamsEvents.spEventsUrl}
                                placeholder='https://{tenant}.sharepoint.com/SitePages/Events.aspx'
                            />
                        </div>
                    </div>
                    <div className='share-div-padding'>
                    <ConditionalWrapper
                        condition={this.state.showPopoverTeams}
                        wrapper={(children) => (
                            <CalloutHover
                                arrow
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                component={
                                    <ShareUrlsToggleOnly
                                        title={"MS Teams"}
                                        shareToggleEvent={ShareUrlEvents.toggleTeamsUrl}
                                        postsShareUrlEvent={TeamsEvents.teamsUrl}
                                        eventsShareUrlEvent={TeamsEvents.teamsEventsUrl}
                                        handleClick={ this.handleSwitchUpdates }
                                        onMouseOver={this.checkTeamsOnMouseOver}
                                    />
                                }
                            >
                                <p>{this.state.calloutMessage}</p>
                            </CalloutHover>
                        )}
                    >
                        <ShareUrlsToggleOnly
                            title={"MS Teams"}
                            shareToggleEvent={ShareUrlEvents.toggleTeamsUrl}
                            postsShareUrlEvent={TeamsEvents.teamsUrl}
                            eventsShareUrlEvent={TeamsEvents.teamsEventsUrl}
                            handleClick={ this.handleSwitchUpdates }
                            onMouseOver={this.checkTeamsOnMouseOver}
                        />
                    </ConditionalWrapper>
                        <p className="description-text">Use a Team that contains the News Central or Events Central tab. Click on "More options" and select "Copy link to tab" for the URL.</p>
                        <div className="input-text-padding">
                            <TextInputField
                                label={"Share Label"}
                                value={this.props.shareUrlsConfig.teamsUrlShareLinkName}
                                onChange={this.handleShareNameTextFields }
                                shareEvent={ShareUrlEvents.teamsUrlShareLinkName}
                                placeholder='MS Teams Link'
                                maxLength={30}
                            />
                        </div>
                        <div className="input-text-padding">
                            <TextInputField
                                label={"Posts"}
                                value={this.props.teams.teamsUrl}
                                onChange={this.handleShareUrlTextFields}
                                shareEvent={TeamsEvents.teamsUrl}
                                placeholder='https://teams.microsoft.com/l/entity/…'
                            />
                        </div>
                        <div className="input-text-padding">
                            <TextInputField
                                label={"Events"}
                                value={this.props.teams.teamsEventsUrl}
                                onChange={this.handleShareUrlTextFields}
                                shareEvent={TeamsEvents.teamsEventsUrl}
                                placeholder='https://teams.microsoft.com/l/entity/…'
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
}

    private handleSwitchUpdates = (toggleEvent: string, urlShareEvent1: string, urlShareEvent2: string) => {
        if(this.props.teams[urlShareEvent1] && this.props.teams[urlShareEvent2] ) {
            this.props.updateShareToggle(toggleEvent, !this.props.shareUrlsConfig[toggleEvent]);
            this.setState( { showPopoverSP: false, showPopoverTeams: false, calloutMessage: "" });
        } 
    }

    private checkTeamsOnMouseOver = (toggleEvent: string, urlShareEvent1: string, urlShareEvent2: string) => {
        if(!!!this.props.teams[urlShareEvent1] && !!!this.props.teams[urlShareEvent2] && this.props.shareUrlsConfig[toggleEvent] === false) {
            this.setState( { showPopoverTeams: true, calloutMessage: "Add share URLs below to enable this setting." });
        }
    }

    private checkSharePointOnMouseOver = (toggleEvent: string, urlShareEvent1: string, urlShareEvent2: string) => {
        if(!!!this.props.teams[urlShareEvent1] && !!!this.props.teams[urlShareEvent2] && this.props.shareUrlsConfig[toggleEvent] === false ) {
            this.setState( { showPopoverSP: true, calloutMessage: "Add share URLs below to enable this setting." });
        }
    }

    private handleShareNameTextFields = (shareEvent: string, value: string) => {
        this.props.updateShareName(shareEvent, value);
    }
    
    private handleShareUrlTextFields = async (shareEvent: string, value: string) => {
        await this.props.updateShareUrl(shareEvent, value);

        this.checkShareTeamsUrlTextFieldsNotEmpty();
        this.checkShareSPUrlTextFieldsNotEmpty();
    }  

    private checkShareTeamsUrlTextFieldsNotEmpty = () => {
        if(!!!this.props.teams.teamsEventsUrl && !!!this.props.teams.teamsUrl) {
            this.props.updateShareToggle(ShareUrlEvents.toggleTeamsUrl, false);
        }
    }

    private checkShareSPUrlTextFieldsNotEmpty = () => {
        if(!!!this.props.teams.spEventsUrl && !!!this.props.teams.spUrl) {
            this.props.updateShareToggle(ShareUrlEvents.toggleSpUrl, false);
        }
    }

    private handleChangePostReminder = (o: ValueLabel) => {
        this.setState({
            ...this.state,
            reminderPostsDaysSelected: o.value === 'days'
        })
        this.props.updatePostsReminders(this.state.postReminderNum, o.value === 'days');
    }

    private handleChangeEventReminder = (o: ValueLabel) => {
        this.setState({
            ...this.state,
            reminderEventsDaysSelected: o.value === 'days'
        })
        this.props.updateEventsReminders(this.state.eventReminderNum, o.value === 'days');
    }

    private handleLinkPreferenceDropdown = (o: ValueLabel) => {
        this.setState({
            ...this.state,
            linkPreference: o.value
        })
        this.props.updateLinkPreference(o.value);
    }

    private sanitizeInput = (e: number) => {
        if(isNaN(e)) {
            return 0;
        }
        else
        {
            return e;
        }
    }
}




const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        notificationSettings: state.settings.notificationSettings,
        pristine: state.settings.pristineNotificationSettingsCopy,
        teams: state.settings.tenantSettings.teams,
        shareUrlsConfig: state.settings.tenantSettings.shareUrlsConfig,
        tenantId: state.settings.tenantSettings.tenantId,
    }),
    {
        updateEmailBlackout: actions.SetDefaultBlackoutForEmail,
        updateSMSBlackout: actions.SetDefaultBlackoutForSMS,
        updateTeamsBlackout: actions.SetDefaultBlackoutForTeams,
        updateMobileBlackout: actions.SetDefaultBlackoutForMobile,
        updatePostsReminders: actions.SetDefaultReminderForPosts,
        updateEventsReminders: actions.SetDefaultReminderForEvents,
        updateLinkPreference: actions.SetLinkPreference,
        updateEmailName: actions.SetEmailName,
        updateEmailSender: actions.SetEmailSender,
        updateShareToggle: actions.SetToggleShareUrl,
        updateShareName: actions.SetShareName,
        updateShareUrl: actions.SetShareUrl,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default (connector(MessagingMainControls));
